define('fusion/log',['require','fusion/jquery','fusion/event','fusion/utils','fusion/log-flags'],function (require) {
    "use strict";

    var $ = require("fusion/jquery");
    var event = require("fusion/event");
    var $utility = require("fusion/utils");
    var logFlags = require("fusion/log-flags");
    var console = window.console;   //use local var from window object to comply with "use strict" in case console isn't there.
    var consoleLogFlags = getConsoleLogFlagsOrDefault();
    var serverLogFlags = getServerLogFlagsOrDefault();


    function consoleLog() {
        try {
            // Modern browsers
            if (typeof console != 'undefined' && typeof console.log == 'function') {
                // Opera 11
                if (window.opera) {
                    var i = 0;
                    while (i < arguments.length) {
                        console.log('Item ' + (i + 1) + ': ' + arguments[i]);
                        i++;
                    }
                }
                    // All other modern browsers
                else if ((Array.prototype.slice.call(arguments)).length == 1 && typeof Array.prototype.slice.call(arguments)[0] == 'string') {
                    console.log((Array.prototype.slice.call(arguments)).toString());
                } else {
                    console.log.apply(console, Array.prototype.slice.call(arguments));
                }
            }
                // IE8
            else if ((!Function.prototype.bind || treatAsIE8) && typeof console != 'undefined' && typeof console.log == 'object') {
                Function.prototype.call.call(console.log, console, Array.prototype.slice.call(arguments));
            }

            // IE7 and lower, and other old browsers
        } catch (ignore) { }
    };


    function log(level) {

        // Need to check and confirm analytic log type
        if (level === logFlags.analytic) {

            var analyticLogLevel = arguments[1];

            var logTypes = ["info","ui","system"];

            if (logTypes.indexOf((analyticLogLevel || "").toLowerCase()) === -1) {
                log(logFlags.error, analyticLogLevel + " is not a known analytic log type.  Args : " + Array.prototype.slice.call(arguments).join(" | "));
            }

        }



        if ((consoleLogFlags & level) === level) postConsoleLog.apply(this, arguments);



        if (level === logFlags.analytic) {
            if ((serverLogFlags & level) === level) postServerAnalyticLog.call(this, arguments);
        }
        else {
            if ((serverLogFlags & level) === level) postServerLog.call(this, arguments);
        }
    }



    //send log to server via web api
    function postServerLog() {
        event.publish("log-collector:post-server-log", arguments);
    }



    function postServerAnalyticLog() {
        //requires four arguments, including logFlag
        //if (arguments.length !== 4) {
        //    //$log.error("Invalid number of arguments to $log.analytic()");
        //    console.log("Invalid number of arguments to $log.analytic()");
        //}

       event.publish("log-collector:post-server-analytic", arguments);
    }




    // send log to browser console
    function postConsoleLog(level) {
        var args = Array.prototype.slice.call(arguments, 1);
		switch (level) {
			case logFlags.error:
				args.forEach(function (item) {
					(console && console.error) ? console.error(item) : consoleLog(item);
				});
				break;
			case logFlags.warning:
				args.forEach(function (item) {
					(console && console.warn) ? console.warn(item) : consoleLog(item);
				});
				break;
			case logFlags.debug:
				args.forEach(function (item) {
					(console && console.debug) ? console.debug(item) : consoleLog(item);
				});
				break;
			case logFlags.analytic:
				//for analytic logs, we do not support multiple logs per call, because we need multiple args to make each call.
				consoleLog(args);
				break;
			default:
				args.forEach(function (item) {
					consoleLog(item);
				});
				break;
		}
    }

    function error(message, detail, skipSendToServer) {
        /// <signature>
        /// <summary>Writes an error message to the log</summary>
        /// <param name="message" type="String">The message to be written</param>
        /// <param name="detail" type="String">Detailed description of the error</param>
        /// <param name="isLoggingError" type="boolean">Indicates whether the error occurred while a different error was being logged</param>
        detail = detail || "";
        if (window.console && console.error) {
            console.error(message + " " + detail);
        } else {
            consoleLog(message + " " + detail);
        }

        //send to server
        if (!skipSendToServer) {
            postErrorLogMessage(message);
        }
    }

    function getConsoleLogFlagsOrDefault() {
        var defaultFlags = logFlags.error | logFlags.warning | logFlags.analytic | logFlags.debug | logFlags.trace;
        var flags = fusion.globals.consoleLogFlags || defaultFlags;
        return flags;
    }

    function getServerLogFlagsOrDefault() {
        var defaultFlags = logFlags.error | logFlags.warning | logFlags.analytic | logFlags.debug | logFlags.trace;
        var flags = fusion.globals.serverLogFlags || defaultFlags;
        return flags;
    }



    /**
     * Gets the CID from the server. 
     * 
     * This was modified in v6.0.7. In 6.0.4 we had to change all cookeis to HTTP only
     * for a security patch. As a result, this method could no longer read the CID cookie
     * and was creating a duplicate. The value is now pulled from the API.
     * 
     * @since 6.0.7
     * */
    function getClientInteractionId() {
        //var def = "00000000-0000-0000-0000-000000000000";

        return new Promise(function (resolve) {
            $.ajax({
                method: 'GET',
                url: fusion.virtualpath + '/fusionapi/fusionCoreApi/GetCid',
                success: function (data) {
                    if (data && data.data) {
                        resolve(data.data);
                    } else {
                        log(logFlags.error, "fusionCoreApi/GetCid returned null");
                        resolve(def);
                    }
                },
                error: function (err) {
                    log(logFlags.error, "fusionCoreApi/GetCid failed");
                    resolve();
                }
            });
        });
    }


    function hasConsoleFlag(flag) {
        return (consoleLogFlags & flag) === flag;
    }


    var _log = {
        error: function () { log.bind(this, logFlags.error).apply(this, arguments); },
        warning: function () { log.bind(this, logFlags.warning).apply(this, arguments); },
        analytic: function () { log.bind(this, logFlags.analytic).apply(this, arguments); },
        debug: function () { log.bind(this, logFlags.debug).apply(this, arguments); },
        trace: function () { log.bind(this, logFlags.trace).apply(this, arguments); },
        getClientInteractionId: getClientInteractionId,
        hasConsoleFlag: hasConsoleFlag,
        flags: logFlags
    };
    
    _log.warn = _log.warning;

    return _log;

});
