define('fusion/ui/controls/fusion-currency',[
    "fusion/fusion.control",
    "ext/jquery.maskMoney"
], function (ControlFactory) {
    return ControlFactory.control(function ($, ko, $log, require) {
        var c = this;

        //require("ext/jquery.maskMoney");

        c.settingsDefinition = {
            value: { isLive: true, defaultValue: '' },
            labelText: { isLive: true, defaultValue: null },
            helperText: { isLive: true, defaultValue: null },
            isPrefixVisible: { isLive: false, defaultValue: true },
            isZeroValuesAllowed: { isLive: false, defaultValue: true },
            isNegativeNumberAllowed: { isLive: false, defaultValue: false }
        };


        c.validateValues = function (settings) {

            // checking for direct content
            if (settings.__hasDirectContent) {
                var msg = "fusion-currency does not support direct content";
                $log.warning(msg);
            }
        }

        c.beforeBind = function ($markup, settings, bindingContext, $element) {
        }
        c.afterBind = function ($markup, settings, bindingContext, $element) {
        }
        c.afterDomInsert = function ($markup, settings, bindingContext) {

            //hook up the input mask
            $markup.find("input").maskMoney({
                prefix: (settings.isPrefixVisible) ? "$" : "",
                allowZero: settings.isZeroValuesAllowed,
                allowNegative: settings.isNegativeNumberAllowed
            });


            //since the inputmkas plugin breaks knockout (of course), manually hookup binding
            $markup.find("input").bind("propertychange keyup input cut paste", function (event) {
                settings.value($(this).maskMoney('unmasked')[0]);           // stripping out a formatting comma for numbers > hundreds
            });




            //set the initial value handle the observable changing
            $markup.find("input").val(settings.value());

            settings.value.subscribe(function (newValue) {

                // checking to see if zero vals are disallowed and throwing an error is newValue is a 0
                if (!settings.isZeroValuesAllowed && newValue === 0) {
                    var msg = "Zero values are not allowed";
                    $log.error(msg);
                    throw new Error(msg);
                }
                else {
                    if ($markup.find("input").maskMoney('unmasked')[0] !== parseFloat(newValue)) {
                        $markup.find("input").maskMoney('mask', parseFloat(newValue));
                    }
                }

            });

        }

    });
});
