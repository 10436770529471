define('fusion/ui/controls/fusion-radioexpander',["fusion/fusion.control"], function (ControlFactory) {
    return ControlFactory.control(function ($, ko) {
        var m = this;

        m.settingsDefinition = {
            header: { isLive: true },
            scrollIntoView: { isLive: true, defaultValue: false },
            selectedValue: { isLive: true }
        };

        m.validateValues = function (settings) {
        }

        m.afterDomInsert = function ($markup, settings, bindingContext) {
        };
    });
});
