define('fusion/ui/controls/fusion-toolbarbutton',["fusion/fusion.control"], function (ControlFactory) {
    return ControlFactory.control(function (ko, $) {
        var c = this;

        c.settingsDefinition = {
            action: { isLive: false, defaultValue: null },
            icon: { isLive: false, defaultValue: "" },
            image: { isLive: false, defaultValue: "" },
            toolTip: { isLive: false, defaultValue: "" },
            isEnabled: { isLive: true, defaultValue: true }
        };


        c.validateValues = function (settings) {
        }


        c.beforeBind = function ($markup, settings, bindingContext, $element) {
        }


        c.afterBind = function ($markup, settings, bindingContext, $element) {
        }


        c.afterDomInsert = function ($markup, settings, bindingContext) {

            $markup.find("button").bind("click", handleActiveButton);

        };



        function handleActiveButton(e) {

            $(".fusion-toolbar button.toolbarActive").removeClass("toolbarActive");
            $(this).addClass("toolbarActive");
        }



    });

});
