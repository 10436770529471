define('ext/toastr/toastr-service',["require", "ext/toastr/toastr", "css!js/ext/toastr/toastr.css"], function (require, toastr) {


    //load async for now to avoid r.js optimization issues.
    //if developer needs it to load early, then require it in the shell like so:
    //    require("css!ext/toastr/toastr.css");

    //require(["css!js/ext/toastr/toastr.css"]);

    toastr.options = {
        showMethod: 'slideDown',
        hideMethod: 'slideUp'
    };

    if (fusion.cordova.isCordova) {
        toastr.options.positionClass = 'toast-cordova-top';
    }

    //toastr.options.onShown = function () {
    //    console.warn('new toastr');
    //    //toastr.clear();

    //    var $container = toastr.getContainer();
    //    if ($container.children().length > 1) {
    //        $container.remove();
    //    }
    //};

    //toastr.options.onHidden = function () {
       
    //};

    // setting Fx level default toastr options overrides - if needed ( will have to apply to all toasts )
    //toastr.options.timeOut = 30;            // How long the toast will display without user interaction
    //toastr.options.extendedTimeOut = 60;    // How long the toast will display after a user hovers over it


    // bad global option examples -- these are more instance specific and should be overridden as part of a toast call options obj passed in
    //toastr.options.closeButton = true;
    //toastr.options.tapToDismiss = false;
    //toastr.options.timeOut = 0;
    //toastr .options.extendedTimeOut = 0;

    return toastr;
});
