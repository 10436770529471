define('fusion/private/control-behavior-binding',['require','knockout','fusion/jquery'],function (require) {

    //this module controls the True-Matter-designed behaviors such as helper text and validation specifics

    var ko = require("knockout");
    var $ = require("fusion/jquery");

    ko.bindingHandlers.controlBehavior = {
        init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {

            var $element = $(element);

            //remove validation decorations if necessary
            if (!bindingContext.$data.showValidationMessage) {
                $element.find("[data-part=validationMessage_PART]").remove();
                $element.find("[data-part=validationStatus_PART]").remove();
            }

            //get references to PARTs that we'll need
            var $validationMessageElement = $element.find("[data-part=validationMessage_PART]");
            var $validationStatusElement = $element.find("[data-part=validationStatus_PART]");
            var $helperTextElement = $element.find("[data-part=helperText_PART]");

            //validation options
            var options = valueAccessor();

            //use input as default selector for help triggers, unless one is set in the binding
            var helpTriggersSelector = options.helpTriggers || 'input';

            //set up the helper text actions and animations, if any
            setHelperTextAnimations(helpTriggersSelector, $helperTextElement, $element);

            //turn off the default validation behavior for this control
            //This need to be done even if no validatable observable is specified, 
            //  to protect against the case where this binding is 
            //  is applied inside another control. (e.g. fusion-radiogroup-item)
            var bindings = {
                validationOptions: {
                    insertMessages: false,
                    decorateInputElement: false
                }
            };
            ko.applyBindingsToNode(element, bindings);

            //only enable the validation logic if a validationObservable is specified
            if (ko.validation.utils.isValidatable(options.validationObservable)) {

                //set up a private observable that we can use for blurMode to decide whether to show the error decorations
                var showErrorBlurMode = ko.observable(false);
                configureBlurMode(options.errorsOnBlur, showErrorBlurMode, options.validationObservable, $element)

                //set up a text binding on the validation message to set the error text
                if ($validationMessageElement.length > 0) {
                    $validationMessageElement.addClass("error");
                    ko.applyBindingsToNode($validationMessageElement[0], { text: options.validationObservable.error });
                }

                //logic to show/hide error decorations at appropriate time
                var $el = $(element);
                ko.computed(function () {
                    var showErrorDecoration = false;

                    if (options.errorsOnBlur) {
                        showErrorDecoration = showErrorBlurMode();
                        //a little cryptic, but this allows the errors to show when validation is triggered from an external source
                        //  such as m.validate() or programatically setting the observable to an invalid value.
                        if (!showErrorDecoration) {
                            if (options.validationObservable.isModified() && !options.validationObservable.isValid()) {
                                if (!$(element).find('input').is(":focus")) {
                                    showErrorDecoration = true;
                                }
                            }
                        }
                    } else {
                        showErrorDecoration = options.validationObservable.isModified() && !options.validationObservable.isValid();
                    }

                    //show or hide the decorations
                    //console.log("showErrorDecoration==" + showErrorDecoration);
                    if (showErrorDecoration) {
                        //apply the `fusion-error` class
                        $el.removeClass("fusion-success").addClass("fusion-error");
                        $validationMessageElement.hide().slideDown('fast').fadeIn('fast');
                        $validationStatusElement.addClass("valid-status").hide().fadeIn('fast');
                    } else {
                        //apply fusion-success
                        $validationStatusElement.fadeOut('fast', function () { $(this).removeClass("valid-status"); });
                        if ($validationMessageElement.length) {
                            $validationMessageElement.slideUp('fast').fadeOut('fast', function () {
                                //console.log("finished showErrorDecoration==" + showErrorDecoration);
                                //manually hide when complete to avoid issue with IE9 where jQuery sets (and leaves) 
                                //  the element style as inline-block during width/height animations.
                                $validationMessageElement.hide();

                                $el.removeClass("fusion-error").addClass("fusion-success");
                            });
                        } else {
                            $el.removeClass("fusion-error").addClass("fusion-success");
                        }
                    }
                }).extend({ throttle: 100 });
            } else {
                //if no validation, then hide the validation parts
                $validationMessageElement.hide();
                $validationStatusElement.hide();
            }
        }
    };

    //used by validation binding, this function sets the triggers and animation
    //  to show the helper text
    function setHelperTextAnimations(helpTriggersSelector, $helperText_PART, $element) {


        //add the helper text class
        $helperText_PART.addClass("helper-text");

        //set up the fusion helper text
        if ($helperText_PART.length > 0) {
            $element.find(helpTriggersSelector).on('focus', function () {

                //if no HTML content, then don't need to ever show the helper
                if ($helperText_PART.contents().length === 0) {
                    return;
                }

                $(' ~ .helper-text', this)
                    .hide()
                    .slideDown('fast')
                    .animate(
                        { opacity: 1 },
                        { queue: false, duration: 'fast' }
                    );
            }).on('blur', function () {
                //use a timeout to give any click events time to fire
                setTimeout(function () {
                    $(' ~ .helper-text', this)
                    .show()
                    .slideUp('fast')
                    .animate(
                        { opacity: 0 },
                        { queue: false, duration: 'fast' }
                    );
                }.bind(this), 100);
            });
        }
    }

    function configureBlurMode(blurElementSelector, showErrorBlurModeObservable, validationObservable, $element) {
        var $blurElement = $element.find(blurElementSelector);
        if ($blurElement.length > 0) {
            $blurElement.one('focus', function () {
                validationObservable.originalValue = validationObservable();
            }).on('blur', function () {
                //update the dirty flag on blur
                showErrorBlurModeObservable(!validationObservable.isValid() && validationObservable.originalValue != validationObservable());

                //if value is valid, then reset the original value
                //this will reset the validation so that it will not show validation error
                //  again until the blur occurs for an invalid value.
                if (validationObservable.isValid()) {
                    validationObservable.originalValue = validationObservable();
                }
            });

            //always immediately clear the error when isValid
            validationObservable.isValid.subscribe(function (newValue) {
                if (newValue) {
                    showErrorBlurModeObservable(false);
                }
            });
        }
    }

});
