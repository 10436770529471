define('fusion/ui/controls/fusion-test',["fusion/fusion.control"], function (ControlFactory) {
    return ControlFactory.control(function ($) {
        var c = this;

        c.settingsDefinition = {
            text: { isLive: true, isRequired: false, defaultValue: 'test' }
        };


        c.beforeBind = function ($markup, settings, bindingContext, $element) {
            if (settings.text == "joseph") {
                settings.name = "Sally";
            }
            $markup.append($("<button>" + settings.text + "</button>"));
        }

        c.afterBind = function ($markup, settings, bindingContext, $element) {
            //$markup.append($("<button>" + settings.text + "</button>"));
        }
    });

});
