define('fusion/ui/controls/fusion-radiogroup',["fusion/fusion.control"], function (ControlFactory) {
    return ControlFactory.control(function () {
        var c = this;


        c.settingsDefinition = {
            headerText: { isLive: true, defaultValue: null },
            selectedValue: { isLive: true, isRequired: true },
            contentMode: { isLive: false, defaultValue: "expander" }
        };

        c.validateValues = function (settings) {
            settings("contentMode").throwIfNot().isOneOf(['expander', 'static']);
        }

        c.beforeBind = function ($markup, settings, bindingContext, $element) {
        }

        c.afterBind = function ($markup, settings, bindingContext, $element) {
        }

        c.afterDomInsert = function ($markup, settings, bindingContext) {
        }

    });
});
