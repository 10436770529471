define('fusion/ui/controls/fusion-textarea',["fusion/fusion.control"], function (ControlFactory) {
    return ControlFactory.control(function ($, ko, $log) {
        var c = this;


        c.settingsDefinition = {
            value: { isLive: true, defaultValue: '' },
            valueUpdate: { isLive: false, defaultValue: 'change' },
            isEnabled: { isLive: true, defaultValue: true },
            labelText: { isLive: true, defaultValue: null },
            helperText: { isLive: true },
            autoStretchHeight: {},
            rows: { isLive: false, defaultValue: null }
        };








        c.validateValues = function (settings) {

            // checking that valueUpdate is an acceptable value
            settings("valueUpdate").throwIf().not().isOneOf(['change', 'input', 'keyup', 'keypress', 'afterkeydown']);

            // checking for direct content
            if (settings.__hasDirectContent) {
                var msg = "fusion-textarea does not support direct content.";
                $log.warning(msg);
                //throw new Error(msg);
            }

            // ensuring rows setting is not observable
            if (ko.isObservable(settings.rows)) {
                var msg = "rows attribute cannot be an observable.";
                $log.error(msg);
            }

            // ensuring rows setting is an integer
            if (parseInt(settings.rows) === NaN) {
                var msg = "rows attribute must be parsable as a number."
                $log.error(msg);
            }

        }



        c.beforeBind = function ($markup, settings, bindingContext, $element) {

            //settings.value.subscribe(handlePasswordSubscribe.bind(c, settings, $markup));
        }



        c.afterBind = function ($markup, settings, bindingContext, $element) {

        }



        c.afterDomInsert = function ($markup, settings, bindingContext) {


            //apply fillHeight property
            //use JS to dynamically size the textarea height to the size of the container.
            if (settings.autoStretchHeight) {

                //run resize handler
                resizeHandler();

                //call handler when window resizes
                $(window).on('resize', resizeHandler);



                //TODO: call handler when helptext displays and also when validation error displays
                //$markup.find(".error").bind('DOMNodeInserted DOMSubtreeModified DOMNodeRemoved', function () {
                //    //if ($markup.find(".error").html()) {
                //        $log.trace("error calling resizeHandler");
                //        resizeHandler();
                //    //}
                //});

                ko.utils.domNodeDisposal.addDisposeCallback($markup[0], function () {
                    $(window).off("resize", resizeHandler);
                });

                //resize textarea column to full height
                function resizeHandler() {
                    var $textArea = $markup.find("textarea");
                    $textArea.height(25);

                    //get new height (parent height - total height of other elements
                    var otherHeight = 0;
                    $markup.children().each(function (i, o) {
                        if (o !== $textArea[0]) {
                            otherHeight += $(o).height();
                        }
                    });

                    $textArea.height($markup.parent().height() - otherHeight);
                };
            }

        }

    });
});
