define("fusion/private/cache", ['require','durandal/system','fusion/utils','fusion/jquery','fusion/log'],function (require) {
    "use strict";
    var CACHE_EXISTS_MESSAGE = "Item cannot be added to cache, because the specified key already exists";
    var CACHE_NOT_FOUND_MESSAGE = "Specified key does not exist in cache";

    var system = require('durandal/system');
    var $utility = require('fusion/utils');
    var $ = require("fusion/jquery");
    var $log = require('fusion/log');
    var _cache;

    init();

    //initialization.  This should happen only once per page session.
    function init() {
        //reset to empty cache object
        resetCache();

        //try to restore from cookie;
        restoreFromCookie();

        $(window).on('beforeunload', function () {
            //when page is unloading, save the current cache to cookie
            return saveToCookie();
        });

    }

    //todo: implement lifetime, and max cache size memory mgt

    function hasKey(key) {
        /// <signature>
        /// <summary>Returns a value indicating whether the specified key value exists in cache</summary>
        /// <param name="key" type="String">The case-insensitive cache key to use when locating the entry in cache</param>
        /// </signature>
        key = (key || "").toUpperCase();
        return _cache.hasOwnProperty(key);
    }

    function resetCache() {
        /// <signature>
        /// <summary>Resets the cache to an empty object.  Any existing cache entries will be lost.</summary>
        /// </signature>
        _cache = {};
    }

    function clear(key, partialMatch) {
        /// <signature>
        /// <summary>Clear entries from cache for the specified key value</summary>
        /// <param name="key" type="String">The case-insensitive cache key to use when locating the entry in cache</param>
        /// </signature>
        /// <signature>
        /// <summary>Remove entries from cache for the specified key value</summary>
        /// <param name="key" type="String">The case-insensitive cache key to use when locating the entry in cache</param>
        /// <param name="partialMatch" type="String">If true, will also remove any cache entries for keys that start with the specified key value</param>
        /// </signature>
        key = (key || "").toUpperCase();
        if (partialMatch) {
            for (var prop in _cache) {
                if (_cache.hasOwnProperty(prop)) {
                    if (prop.indexOf(key) > -1) {
                        delete _cache[prop];
                    }
                }
            }
        } else {
            if (hasKey(key)) {
                delete _cache[key];
            }
        }
    }

    //deprecated
    function remove(key, partialMatch) {
        $log.warning("`$cache.remove()` has been deprecated.  Use `$cache.clear()` instead.");
        return clear(key, partialMatch);
    }

    function getUniqueCacheId() {
        /// <summary>Gets a unique identifier for cache that isn't currently in cache</summary>
        var i = 1000;
        do {
            i++;
            var cid = "cid" + i.toString(16);
        } while (hasKey(cid))
        return cid;
    }

    function get(key, destroy, throwIfNotFound) {
        /// <signature>
        /// <summary>Returns a value from cache for the specified key</summary>
        /// <param name="key" type="String">The case-insensitive cache key to use when locating the data in cache</param>
        /// </signature>
        /// <signature>
        /// <summary>Returns a value from cache for the specified key (case-insensitive) and optionally destroys the cache entry</summary>
        /// <param name="key" type="String">The case-insensitive cache key to use when locating the data in cache</param>
        /// <param name="destroy" type="Boolean">Specifies whether to destroy the cache entry once the value has been returned</param>
        /// <param name="throwIfNotFound" type="Boolean">Specifies whether the function should throw an error if the cache was not found</param>
        /// </signature>
        key = (key || "").toUpperCase();
        if (throwIfNotFound && !hasKey(key)) {
            throw new Error(CACHE_NOT_FOUND_MESSAGE);
        }
        var value = _cache[key];
        if (destroy) {
            remove(key);
        }
        return value;
    }

    function set(key, data, overwrite) {
        /// <signature>
        /// <summary>Sets a cache entry for the specified key (case-insensitive) and data.  Data cache using this method is only stored in local memory and is lost when the page reloads.</summary>
        /// <param name="key" type="String">Specifies the case-insensitive key to use when creating the cache entry.  If the specified key exists an error will be thrown</param>
        /// <param name="data" type="Object">The data to store in the cache</param>
        /// <param name="overwrite" type="Object">If true, will quietly overwrite an existing entry, even if specified key already exists</param>
        /// </signature>
        /// <signature>

        /// <summary>Creates a new cache entry for the specified data and returns the cache key for the new entry.  Data cache using this method is only stored in local memory and is lost when the page reloads.</summary>
        /// <param name="data" type="Object">The data to store in the cache</param>
        /// <returns type="String" />
        /// </signature>
        if (arguments.length == 1) {
            data = key;
            var done = false;
            while (!done) {
                try {
                    var cid = getUniqueCacheId();
                    addEntry(cid, data);
                    return cid;
                }
                catch (err) {
                    if (err.message == CACHE_EXISTS_MESSAGE) {
                        //ignore and allow retry
                    } else {
                        throw err;
                    }
                }
            }
        } else {
            return addEntry(key, data, overwrite);
        }

    }

    function addEntry(key, data, forceOverwrite) {
        /// <signature>
        /// <summary>Creates a new cache entry for the specified key and data.  Data cache using this method is only stored in local memory and is lost when the page reloads.</summary>
        /// <param name="key" type="String">Specifies the case-insensitive key to use when creating the cache entry.  If the specified key exists an error will be thrown</param>
        /// <param name="data" type="Object">The data to store in the cache</param>
        /// <param name="forceOverwrite" type="Object">If true, will quietly overwrite an existing entry, even if specified key already exists</param>
        /// </signature>
        key = (key || "").toUpperCase();
        if (!forceOverwrite && typeof _cache[key] !== "undefined") {
            system.log(CACHE_EXISTS_MESSAGE);
            throw new Error(CACHE_EXISTS_MESSAGE + " Key: " + key);
        } else {
            if (typeof key !== "undefined") {
                _cache[key] = data;
            } else {
                var CACHE_KEY_UNDEFINED_MESSAGE = "Specified cache key is undefined";
                throw new Error(CACHE_KEY_UNDEFINED_MESSAGE);
            }
        }
        return key;
    }


    function saveToCookie() {
        //persist the cache object to cookie so that it can be retrieved in case page is reloaded.
        var cookieValue = JSON.stringify(_cache);
        var cookieLength = encodeURI(cookieValue).split(/%..|./).length - 1;
        $utility.cookie("fc1", cookieValue);
 //       return cookieValue;
    }

    function restoreFromCookie() {
        var cacheCookie = $utility.cookie("fc1");
        if (cacheCookie) {
            try {
                _cache = JSON.parse(cacheCookie);
            } catch (e) {
                //$log.error("Error restoring cookie: " + e);
            }
        }
    }


    return {
        expired: function (key) {
            //TODO: implement lifetime/expire logic
            return false;
        },
        hasKey: hasKey,
        remove: remove, /* `remove` has been deprecated, since `clear` aligns more closely with typical verbiage */
        clear: clear,
        resetCache: resetCache,
        get: get,
        set: set
    };

});
