define('fusion/ui/controls/fusion-appletheader',["fusion/fusion.control"], function (ControlFactory) {
    return ControlFactory.control(function (require, $, $log, ko, $navigation, $utility) {
        var c = this;


        c.settingsDefinition = {
            applicationName: { isRequired: true, isLive: false },
            iconUrl: { isRequired: false, isLive: false },
            homePath: { isRequired: false, isLive: false },
            isMenuHoverable: { isRequired: false, isLive: false, defaultValue: true },
            isHeaderFixed: { isRequired: false, isLive: false, defaultValue: false },
            isMenuHidden: { isRequired: false, isLive: true, defaultValue: false },
        }


        c.validateValues = function (settings) {
            //checking for direct content
            //if (settings.__hasDirectContent) {
            //    var msg = "fusion-appletheader does not support direct content";
            //    $log.warning(msg);
            //}

            //if (typeof settings.isMenuHoverable !== "boolean" && !$utility.tryParseBool(settings.isMenuHoverable)) {
                if (typeof settings.isMenuHoverable !== "boolean" && !( settings.isMenuHoverable === 'true' || settings.isMenuHoverable === 'false') ) {
                var msg = "Error in fusion-appletheader : isMenuHoverable attribute must resolve to a Boolean.  Current type is : " + typeof settings.isMenuHoverable + ", setting to true for control stability";
                $log.warning(msg);
                settings.isMenuHoverable = true;
            }

        };



        c.beforeBind = function ($markup, settings, bindingContext, $element) {
            //$data.get("GetFxWarningsAndNotices", "fusionCoreApi")
            //.then(function (noticeFragment) {

            //    settings.noticeFragment(noticeFragment);
            //});

            settings.navHome = function () {
                if (settings.homePath != null) {
                    $navigation.navigateTo({ routeName: settings.homePath });
                }
            }

            settings.router = require('durandal/plugins/router');

            settings.getSubItems = function (parentItem) {
                var items = [];
                // skip if default route
                if (parentItem.route !== "" && parentItem.route !== null && parentItem.id) {
                    items = this.router.navigationModel().filter(function (item) {
                        return item.parentId === parentItem.id
                    })
                }
                return items;
            }
        }


        c.afterDomInsert = function ($markup, settings, bindingContext, $element) {

            //ensuring isMenuHoverable is a bool
            settings.isMenuHoverable = $utility.tryParseBool(settings.isMenuHoverable);

            settings.router.on("router:navigation:complete").then(function (instance, instruction) {
                var parentId = instruction.config.parentId;

                //clear active class on all parent nav items
                $(".top-bar li.has-dropdown.active").removeClass("active");
                if (parentId) {
                    //set active class on active parent item
                    $(".top-bar li.has-dropdown[data-parent-id=" + parentId + "]").addClass("active");
                }
            });

            fusion.isFoundationReady().then(function () {
                $(document).foundation({
                    topbar: {
                        //custom_back_text: false,
                        //is_hover: (settings.isMenuHoverable === 'true'),
                        is_hover: settings.isMenuHoverable,
                        //mobile_show_parent_link: true
                    }
                });
                $(document).foundation('topbar', 'reflow');          //Causes sticky class of undefined error without $(document).foundation()
            });

        }

    });

});
