define('fusion/ui/controls/fusion-textbox',["fusion/fusion.control"], function (ControlFactory) {

    return ControlFactory.control(function ($, ko, $log) {
        var c = this;


    c.settingsDefinition = {
        value: { isLive: true, defaultValue: '' },
        labelText: { isLive: true, defaultValue: null },
        placeholder: { isLive: true, defaultValue: null },
        helperText: { isLive: true, defaultValue: null },
        isAutoCompleteEnabled: {isLive: false, defaultValue: false},
        type: { defaultValue: 'text' },
        isReadOnly: { isLive: true, defaultValue: false },
        maxLength: { isLive: false, defaultValue: null },
        isEnabled: { isLive: true, defaultValue: true },
        isFocused: { isLive: true, defaultValue: false },
        updateOnKeystroke: {isLive: false, defaultValue: false}
    };

    // http://knockoutjs.com/documentation/hasfocus-binding.html -- idea on editability, etc. using focus and editing concepts


        c.validateValues = function (settings) {

            // checking for direct content
            if (settings.__hasDirectContent) {
                var msg = "fusion-textbox does not support direct content";
                $log.warning(msg);
                //throw new Error(msg);
            }

            settings("type").throwIf().not().isOneOf(['text', 'email', 'phone', 'tel', 'url', 'number', 'date', 'currency', 'numericText']);
            //settings("valueUpdate").throwIf().not().isOneOf(['', 'input', 'keyup', 'keypress', 'afterkeydown']);


            // ensuring maxLength is parsable as an int
            settings("maxLength").throwIf(function (val) {
                return val && isNaN(parseInt(val));
            },
            "maxLength cannot be evaluated as an integer value.");
        }



        c.beforeBind = function ($markup, settings, bindingContext, $element) {

            if (settings.type === 'phone') {
                settings.type = 'tel';
            }


            //settings.value.subscribe(handlePasswordSubscribe.bind(c, settings, $markup));

            settings.autoComplete = (settings.isAutoCompleteEnabled) ? "on" : "off";


        }



        c.afterBind = function ($markup, settings, bindingContext, $element) {
        }



        c.afterDomInsert = function ($markup, settings, bindingContext) {
        }

    });
    
});
