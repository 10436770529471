define('fusion/ui/controls/fusion-linkexpander-panel',["fusion/fusion.control"], function (ControlFactory) {
    return ControlFactory.control(function ($, ko, require) {
        var c = this;

        c.settingsDefinition = {
            text: { isLive: true },
            isExpanded: { isLive: true, defaultValue: false }
        };

        c.validateValues = function (settings) {
        };

        c.beforeBind = function ($markup, settings, bindingContext, $element) {
        };

        c.afterDomInsert = function ($markup, settings, bindingContext, $element) {

            //set up a computed to open and close accordion when the isExpanded observable value changes
            ko.computed(function () {

                var scrollIntoView = bindingContext.$parentControl.scrollIntoView;      // note that setting of 'false' ( string val ) will be treated as true in this case

                var ce = $markup.find('a').first();
                var el = ce.next('.content');

                if (settings.isExpanded()) {
                    expanderOpen(el, ce, scrollIntoView());
                } else {
                    expanderClose(el, ce);
                }
            });

            //set up the click handler
            var a = $markup.find('a').first();
            a.click(function (e) {
                var ce = $(this);
                var el = $(this).next('.content');

                if (el.is(':hidden')) {
                    expanderOpen(el, ce);
                }

                else {
                    expanderClose(el, ce);
                }
                return false;

            });



            //expander open/close functions
            var $root = $('html, body');

            function expanderOpen(openElement, clickedElement, scrollIntoView) {

                settings.isExpanded(true);

                clickedElement.parent().find('#' + settings.instanceId + '-checkbox').prop('checked', true);
                clickedElement.addClass('active')

                openElement.slideDown(function () {

                    if ($root) {                    // ensuring $root is OK - can be momentarily undefined on page reload

                        _openAnimation($root, clickedElement, scrollIntoView);

                    }
                    else {
                        setTimeout(function () {
                            $root = $('html, body');

                            _openAnimation($root, clickedElement, scrollIntoView);

                            openElement.css("display", "block");       // ensuring panel content is visible

                        }, 300);
                    }

                });

            }


            // private animation helper function
            function _openAnimation($root, clickedElement, scrollIntoView) {

                if (scrollIntoView) {
                    $root.animate({
                        scrollTop: $(clickedElement).offset().top
                    });
                }

            }

            function expanderClose(closeElement, clickedElement) {

                settings.isExpanded(false);

                clickedElement.parent().find('#' + settings.instanceId + '-checkbox').prop('checked', false);
                clickedElement.removeClass('active')

                closeElement.slideUp();

            }

        };

    });
});
