define('fusion/applet',["fusion/utils", "fusion/jquery", "durandal/system", "fusion/log", "fusion/data", "fusion/navigation", "durandal/plugins/router", "durandal/app", "fusion/config"],
    function (utils, $, system, log, data, navigation, router, durandalApp, $config) {
        "use strict";

        var Applet = function () {

            var applet = this;

            var startTime = (new Date).getTime(); // after the title in my page

            //TODO:  This should come from the web mod framework
            //  and should not be required to be called by apps that do not need it.
            function getSbuContext() {
                var dfd = new $.Deferred();
                data.get({
                    action: "GetSbuContext",
                    controller: "WebModFrameworkApi",
                    parameters: { configPath: applet.configPath },
                    serverUrl: applet.serverUrl,
                    loginUrl: applet.loginUrl
                })
                .then(function (serverAppletContext) {
                    $.extend(applet, serverAppletContext)
                    log.debug("applet.getSbuContext complete: " + applet.appletId);
                    dfd.resolve();
                })
                .fail(function () {
                    system.error("Error getting applet context for appletId " + applet.appletId);
                    dfd.reject();
                });

                return dfd.promise();
            };

            this.initAppletTag = function (element) {
                log.trace("inside applet.initAppletTag()");

                var initialViewModel = $(element).attr("viewmodel");        // grabbing viewmodel attr from the applet tag

                //validate settings
                if (!initialViewModel) {
                    log.error("WARNING: The fusion-applet element attribute, viewmodel, is missing.");
                }

                //ensure that slashes are correct
                initialViewModel = utils.string.trim(initialViewModel);
                if (initialViewModel.indexOf("/") === 0) {
                    throw new Error("viewModel attribute on the Fusion-Applet tag cannot start with a '/'");
                }
                if (initialViewModel.indexOf("\\") > -1) {
                    throw new Error("viewModel attribute cannot contains blackslash (\) characters.  Ensure that all slashes are foward-slashes.");
                }

                //show the applet - sets the root for a Durandal app.  Basically sticks the view and viewmodel (normally shell) together - results actuall get placed between the <fusion-applet> tag
                //  and uses Knockout to bind the view and viewmodel ( before it is placed in the page )
                durandalApp.setRoot(initialViewModel, null, element);  
            }

            var loggedOnce = false;
            this.processAppletTags = function () {
                if (!loggedOnce) {
                    loggedOnce = true;
                    log.trace("Begin processAppletTags()");
                }
                //loop through applet tags
                var appletTags = $("fusion-applet");            // getting all applet tags
                appletTags.each(function () {                   // processing each one
                    var $element = $(this);
                    if (!$element.attr("processed")) {          // process applet if it has not already been processed
                        log.trace("Found an <fusion-applet> tag to process.", utils.getCurrentLineNumber(), "applet.js");
                        $element.attr("processed", true);       // adding processed indicator for the applet
                        applet.initAppletTag(this);             // initializing the applet
                    }
                });

                //re-run this for 30 seconds to inspect for any fusion-applet tags ( initially driven by need to have applets in Sitefinity which added applet tags in a random way timing wise)
                //  that we missed due to page load timing
                if (((new Date).getTime() - startTime) < 30000) {
                    setTimeout(applet.processAppletTags, 100);
                } else {
                    log.debug("Done processing applet tags.  Found " + appletTags.length + " fusion-applet tags in this page", utils.getCurrentLineNumber(), "applet.js");
                }
            }



            function getAppletNameFromViewModel(viewModel) {
                return viewModel.substr(0, viewModel.indexOf("/"));
            }
        };

        return new Applet();

    });
