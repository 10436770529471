define('fusion/ui/controls/fusion-dropdown',["fusion/fusion.control"], function (ControlFactory) {
    return ControlFactory.control(function ($, ko, $log) {
        var c = this;

        //`option` binding that helps create optGroup elements: http://stackoverflow.com/a/11190148/91189
        ko.bindingHandlers.option = {
            update: function (element, valueAccessor)
            {
                var value = ko.utils.unwrapObservable(valueAccessor());
                ko.selectExtensions.writeValue(element, value);
            }
        };

        c.settingsDefinition = {
            items: { isLive: true, defaultValue: ko.observableArray([]) },
            selectedValue: { isLive: true },
            selectedItem: { isLive: true, isRequired: true },
            displayMember: {},
            valueMember: {},
            defaultItemText: { isLive: true },
            labelText: {},
            isEnabled: { isLive: true, defaultValue: true },
            useLegacyDropdown: { defaultValue: false }
        };

        /* There are 3 main usage scenarios for this control (that I can think of) 
         * 1) Binding to a list of Primitive Types 
         * 2) Binding to a list of objects + need for selectedObject property
         * 3) Binding to a list of objects + need for selectedValue (from valueMember) and/or object
         * 
         * In all 3 scenarios, potential implementations include: 
         * A) Initializing selectedValue or selectedItem in the onActivate 
         * B) Programmatically changing selectedValue or selectedItem 
         * C) Setting the observableArray that the dropdown binds to AFTER the v/vm has loaded
         * D) not specifying a selectedValue and/or selectedItem property for the control
         * E) Dynamically generating the control and it's properties/values 
         * F) Programmatically clearing the list and selectedItem/selectedValue */

        c.validateValues = function (settings)
        {
            if (settings.useLegacyDropdown === true || settings.useLegacyDropdown === 'true')
            { // Depreciation reminded for legacy dropdown
                $log.warning("Fusion dropdown: You are using a depreciated implementation of the fusion-dropdown");
            }

            if ((settings.items() === undefined || settings.items().length === 0) && (settings.selectedItem() !== undefined || settings.selectedValue() !== undefined))
            { // Warn if no items but user sets a selectedItem or selectedValue 
                $log.warning("Fusion dropdown: you've initialized a selectedItem or selectedValue when there are no items in your list!");
            }

            //#region Legacy code for OptGroup
            /* items is expected to be in one of two formats:
               - an array of options for the dropdown
               - an array of optionGroup objects, identified by the presence of a groupLabel property on each item.*/
            //TODO: Depreciate: 8/27/18 -- DD45578
            ko.computed(function ()
            {
                var items = settings.items();
                if (items.length === 0)
                {
                    return;
                }
                var isGroups = isGroup(items[0]);
                if (isGroups === true)
                {
                    $log.warning("The grouping feature is deprecated. If losing this feature breaks your code, please set the useLegacyDropdown property on your fusion-dropdown to true.");
                }
                items.forEach(function (item)
                {
                    if (!(isGroups === isGroup(item))) // Groups must be all or none
                    {
                        $log.error("The items property may not contain mixed groups and items.");
                    }
                });
            });
            //#endregion Legacy code for OptGroup

            // TODO: Have this validation occur once items are set??? Maybe we call validate again once the list is set?  
            if (settings.items().length > 0) // Need 1+ items to do these checks, otherwise don't know object model.
            {
                if (settings.isPrimitive === false)
                { // Scenarios 2 and 3
                    var stringDisplayMember = ko.utils.unwrapObservable(settings.displayMember); // If property is observable, remove () to pass check.
                    if (settings.items()[0].hasOwnProperty(settings.displayMember) === false && settings.items()[0].hasOwnProperty(stringDisplayMember) === false)
                    { // If items and but user's displayMember is not found on the first item, send error. 
                        $log.error("Fusion dropdown: " + settings.displayMember + " is not a property on the list of items given!");
                    }

                    var stringValueMember = ko.utils.unwrapObservable(settings.displayMember); // If the property is observable, this allows it to pass check.
                    if (settings.items()[0].hasOwnProperty(settings.valueMember) === false && settings.items()[0].hasOwnProperty(stringValueMember) === false)
                    { // Same as above but for valueMember
                        $log.error("Fusion dropdown: " + settings.valueMember + " is not a property on the list of items given!");
                    }

                }
            }
        } // #endregion validateValues

        //TODO: Depreciate: 8/27/18 -- DD45578
        function isGroup(item)
        {
            return item.hasOwnProperty("group") && item.hasOwnProperty("items");
        }

        c.beforeBind = function ($markup, settings, bindingContext, $element)
        {
            settings.isGroup = isGroup;

            /* distinctVal - Is value of selectedValue different from selectedItem? true = Scenario 3: requires valueMember and optional use of displayMember. 
             * IsPrimitive - Is items list of Primitive types? true = Scenario 1: No displayMember or valueMember and distinctVal is false. Always. 
             * If Both = false = Scenario 2: User only cares about selectedObject */
            if (settings.displayMember === undefined && settings.valueMember === undefined)
            { // No display or value member means either primitive list or user error. Scenario 1.  
                settings.isPrimitive = true;
                settings.distinctVal = false;
            }
            else
            { // Must be a list of objects if value/display Member given. (Scenario 2 or 3)  
                settings.isPrimitive = false;
                settings.distinctVal = true;
                if (settings.valueMember === undefined) // If no valueMember: scenario 2, else scenario 3. 
                { // (Scenario 2)
                    settings.distinctVal = false;
                }
            }
        } // #endregion beforebind

        c.afterBind = function ($markup, settings, bindingContext, $element)
        {
            if (settings.useLegacyDropdown === false)
            {
                //#region user initialized object or value
                if (settings.items() !== undefined && settings.items().length !== 0)
                {
                    if (settings.distinctVal === true)
                    { // If Scenario 3
                        if (settings.selectedItem() !== undefined)
                        { // If selectedItem initialized, populate selectedValue with valueMember value.
                            settings.selectedValue(settings.selectedItem()[settings.valueMember]);
                        }

                        else if (settings.selectedValue() !== undefined)
                        { // If selectedValue initialized,  
                            ko.utils.arrayFirst(settings.items(), function (item)
                            {
                                if (item[settings.valueMember] === settings.selectedValue())
                                { // Grab the matching item from the items list and set selectedItem
                                    settings.selectedItem(item);
                                }
                            });
                        }
                    }

                    else if (settings.distinctVal === false)
                    { // Scenario 1 or 2 
                        if (settings.selectedItem() !== undefined)
                        { // Match selectedValue with initialized selectedItem
                            settings.selectedValue(settings.selectedItem());
                        }
                        else if (settings.selectedValue() !== undefined)
                        {
                            settings.selectedItem(settings.selectedValue());
                        }
                    }
                }        //#endregion if user initializes object or value
            }
        } // #endregion AfterBind


        c.afterDomInsert = function ($markup, settings, bindingContext)
        {
            /* Because of how this control's elements are constructed, it is necessary to 
             * apply the binding here after the other bindings have completed in order to 
             * avoid false validation errors. */
            ko.applyBindingsToNode($markup.find("select")[0],
            {
                value: settings.selectedItem,
                valueAllowUnset: true // Needed to prevent premature validation. See: http://knockoutjs.com/documentation/value-binding.html#using-valueallowunset-with-select-elements
            });

            if (settings.useLegacyDropdown === false)
            {
                if (settings.items() !== undefined) // Can't refer to undefined items list. 
                {
                    settings.selectedItem.subscribe(function () // Called when user makes dropdown selection or dev programmatically changes value.
                    {
                        var isInitial = settings.selectedItem() === undefined; // SelectedItem = undefined during initialization. Don't do anything then.  
                        if (settings.distinctVal === true && !isInitial)
                        { // Scenario 3: Set selectedValue based on the selected Object
                            if (settings.selectedValue() !== settings.selectedItem()[settings.valueMember])
                            { // only do this if selectedValue different from object's valueMember value (to avoid extra loops) 
                                settings.selectedValue(settings.selectedItem()[settings.valueMember]);
                            }
                        }
                        else
                        { // distinctVal = false
                            if (settings.selectedValue() !== settings.selectedItem())
                            { // Scenario 1 or 2, make value = item 
                                settings.selectedValue(settings.selectedItem());
                            }
                        }
                    });

                    settings.selectedValue.subscribe(function () //  Used in programmatic changes of selectedValue
                    {
                        if (settings.distinctVal === true)
                        { // Scenario 3
                            //if ((settings.selectedValue() !== undefined && settings.selectedItem() === undefined) || (settings.selectedValue() !== settings.selectedItem()[settings.valueMember]))
                            if ((settings.selectedValue() !== undefined && settings.selectedItem() === undefined) || ((settings.selectedItem() && settings.selectedItem().hasOwnProperty(settings.valueMember)) && (settings.selectedValue() !== settings.selectedItem()[settings.valueMember])))
                            { // If value is set and selectedItem is undefined, dev has initialized a value. If they are just different = programmatic change. (Statement 2 has to happen 2nd to avoid undefined reference error for valueMember)
                                ko.utils.arrayFirst(settings.items(), function (item)
                                {
                                    if (item[settings.valueMember] === settings.selectedValue())
                                    { // Set selectedItem based on valueMember
                                        settings.selectedItem(item);
                                    }
                                });
                            }
                        }
                        else
                        { // Scenario 1 or 2
                            //if (typeof (settings.selectedValue()) !== typeof (settings.items()[0]))
                            //{ // Assumption.net Guess...  
                            //    $log.warning("Is your fusion-dropdown missing a value Member declaration?");
                            //}
                            settings.selectedItem(settings.selectedValue());

                        }
                    });

                }
            }
        }

    });
});
