define('fusion/event',['require','fusion/jquery'],function (require) {
    "use strict";

    var event = {};
    var $ = require("fusion/jquery");

    event.publish = function (eventId, message) {
        $(event).trigger(eventId, message);
        return true;
    }



    event.subscribe = function (eventId, callback) {
        $(event).on(eventId,
            function (e, params) {
                callback(params);
            });
        return true;
    }


    event.unsubscribe = function () {
        return true;
    }


/*
    //JWG 12/27/2017 need to be abe to unsubscribe events - otherwise causes memory leaks
    event.subscribe = function (eventId, callback) {
        var subscription = function (e, params) {
            callback(params);
        };

        $(event).on(eventId,cb);
        return subscription;
    }


    event.unsubscribe = function (eventId, subscription) {
        $(event).off(eventId, subscription);
        return true;
    }

*/
    return event;
});
