// this module provides configuration information for the page
define('fusion/config',["fusion/utils", "fusion/jquery", "fusion/log", "fusion/message"], function (utils, $, $log, $message) {
    "use strict";

    var config = {
        baseUrl: "/",
        navigationConfig: "",
        loadFromPage: loadFromPage,
        controlGroups: { "fusion": "fusion/ui/controls" }               // prepopulated to know controls are in fusion/ui/controls folder -- can be appended later if using custom control in another project ( from an eleemnt in fusion-config on host page)
    };

    //parse the page for a fusion-config element, and when found, process it.
    function loadFromPage() {

        return getConfigElement()
        .then(processConfigElement);

    }

    function getConfigElement() {
        $log.trace("looking for fusion-config element");
        return new Promise(function (resolve, reject) {
            waitForConfigElement(resolve);
        });
    }

    function waitForConfigElement(resolve) {
        var $configElement = $("fusion-config");
        if ($configElement.length === 0) {
            setTimeout(waitForConfigElement.bind(this, resolve), 30);
        } else if ($configElement.length > 1) {
            throw new error("The page cannot contain multiple <fusion-config> elements.");
        } else {
            resolve($configElement);
        }
    }

    function processConfigElement(configElement) {

        $log.trace("processing fusion-config element");

        var promises = [];

        config.baseUrl = configElement.attr("baseUrl");
        config.version = configElement.attr("version");

        // SH46000 - 11/2/18
        // Moved to client api call.
        //var isEnterprise = configElement.attr("isEnterprise");
        //window.fusion.isEnterprise = (isEnterprise !== null && isEnterprise !== undefined) ? isEnterprise === "true" : true;

        // SH 7/27/18 
        // For now we are setting this flag on the global fusion namespace. However in the future it
        // may be beneficial to define a fusion-globals module that is required as a dependency.
        //
        // Setting this config says that "any module name 'fusion-config' has access
        // to these variables." So if we require a fusion-global module that exposes
        // these we can access global variables.
        //window.require.config({
        //    config: {
        //        "fusion-global": {
        //            isEnterprise: isEnterprise
        //        }
        //    }
        //});

        //set up $config.naivation array
        //additional fusion-navigation elements are processed later
        config.navigation = [];

        //this is for backwards compatibility (ideally we'd only support fusion-navigation elements.
        var mainNavigationConfig = configElement.attr("navigationConfig");
        if (mainNavigationConfig) {
            config.navigation.push(mainNavigationConfig);
        }

        validateConfigElementSettings(config);

        setCacheBuster(config.version);

        window.require.config({
            baseUrl: config.baseUrl
        });

        //process fusion-alias tags - for example, fusion-alias allows developer to set an alias where "applet" doesn't match the actual folder name.  
        // Real world example : "applet" can be aliased to /applet vs /applet.min.  Also modularity is a reason too like web mod.
        configElement.find("fusion-alias").each(function () {
            loadAlias(this);
        });

        //process fusion-settings tags - these are applet configuration settings found in applet, i.e. web mod SBU phone numbers, etc.
        configElement.find("fusion-settings").each(function () {
            var info = getSettingsInfo(this);
            promises.push(
                loadSettings(info).then(loadSettingsFromAttributes.bind(this, info))
            );
        });

        //process fusion-messages tags - processes messages tag
        configElement.find("fusion-message").each(function () {
            promises.push(
                processMessageTag(this)
            );
        });

        //process fusion-controlgroup tags -- how you can create custom controls that are local to a project but use Fx control infrastructure
        //control-group should have a name and path attribute 
        //  (e.g. <fusion-controlgroup name="fusion" path="fusion/ui/controls" />)
        configElement.find("fusion-controlgroup").each(function () {                    // configElement is <fusion-config> in host page, so looking for fusion-controlgroup element in fusion-config
            config.controlGroups[$(this).attr("name").toLowerCase()] = $(this).attr("path");  
        });


        //process fusion-navigation tags
        configElement.find("fusion-navigation").each(function () {          // collects all nav tags
            var navigationConfig = $(this).attr("path");
            config.navigation.push(navigationConfig);                       // pushes the nav path on to an array for later use
        });

        return Promise.all(promises);
    }


    function setCacheBuster(appVersion) {
        window['require'].config({ urlArgs: appVersion });  //applies cache-buster to require js args.
    }


    function loadAlias(aliasElement) {
        var paths = {};
        var $aliasElement = $(aliasElement);
        paths[$aliasElement.attr("name")] = $aliasElement.attr("path");

        window.require.config({
            paths: paths
        });
    }

    function getSettingsInfo(settingsElement) {
        var $settingsElement = $(settingsElement);

        var settingsGroup = $settingsElement.attr("name");
        var settingsPath = $settingsElement.attr("path");

        if (!settingsGroup) {
            throw new Error("<fusion-settings> element is missing the `name` attribute.  This attribute should specify the name of the settings group to use.");
        }

        //ensure settings group exists
        if (!config[settingsGroup]) {
            config[settingsGroup] = {};
        }

        //create an object that we can use when processing settings
        return {
            attributes: settingsElement.attributes,
            group: settingsGroup,
            path: settingsPath,
            settings: config[settingsGroup]
        };
    }

    function loadSettings(info) {
        //process the <fusion-settings> elements
        return new Promise(function (resolve, reject) {

            if (info.path) {
                require(["text!" + info.path], function (json) {
                    try {
                        //extend the existing settings group with settings from the JSON
                        $.extend(info.settings, JSON.parse(json));
                    } catch (e) {
                        throw new Error("Error parsing JSON from the following path: " + info.path + ".  If the path exists, ensure that it returns only valid JSON, and ensure that the web.config is configured to serve the MIME type for the file extension.");
                    }
                    resolve();
                },
                function (err) {
                    throw new Error("Could not find the specified settings path: " + info.path + ".  If the path exists, ensure that it is located at the applet root, and that the web.config is configured to serve the json MIME type.");
                    resolve();
                });
            } else {
                resolve();
            }

        });
    }

    function processMessageTag(tag) {
        var $tag = $(tag);

        var key = $tag.attr("key");
        return $message.getMessages(key);
    }

    function loadSettingsFromAttributes(info) {
        //add each attribute as a setting on the config object
        $.each(info.attributes, function (i, attrib) {
            switch (attrib.name.toLowerCase()) {
                //don't process name or path attributes, since they are properties of the <fusion-settings> tag itself.
                case "name":
                case "path":
                    break;
                default:
                    //warn if overwriting an existing value
                    if (info.settings[attrib.name]) {
                        $log.warn("Settings from <fusion-settings> attribute already exists: " + attrib.name);
                    }

                    //define setting (this will overwrite any existing value
                    info.settings[attrib.name] = attrib.value;
            }
        });
    }

    function validateConfigElementSettings(config) {

        //validate config.baseUrl
        if (utils.isNullOrEmpty(config.baseUrl)) {
            config.baseUrl = utils.url.ensureTrailingSlash(config.baseUrl);
        }

        //validate config.navigationConfig
        if (!utils.isNullOrEmpty(config.navigationConfig)) {
            if (utils.string.endsWith(config.navigationConfig.toLowerCase(), ".config")) {
                throw new Error("navigationConfig attribute on FUSION-CONFIG element must not end with .config.  Files ending with .config cannot be served from IIS.  Rename the file to a different extension such as .json or .xml");
            }
        }

        //validate version
        if (!config.version) {
            //log as error to get better attention
            $log.error("Cache-busting is disabled because no version attribute is specified on the FUSION-CONFIG element.");
        }
        else {
            // allow optional fourth version for revision.  This allows us to use Razor to pass in assembly version.
            if (!/^(\d+)\.(\d+)\.(\d+)(\.(\d+))?$/.test(config.version)) {
                throw new Error("version attribute on FUSION-CONFIG element must be in the following format: major.minor.revision and only contain numbers and periods to separate the parts.");
            }
        }
    }

    return config;

});
