"use strict";
define('fusion/ui/controls/fusion-datepicker',[
    "fusion/fusion.control",
    "css!js/ext/jquery-timepicker.css",
    "ext/jquery-timepicker"
], function (ControlFactory) {
    return ControlFactory.control(function ($, ko, require, moment, $log, $utility) {
        var c = this;

        //require("css!ext/jquery-timepicker.css");
        //require("ext/jquery-timepicker");
        //$.datepicker.parseDate = function (format, value) {
        //    var d = new moment(value)._d;
        //    return d != "Invalid Date" ? d : new Date();
        //};

        c.settingsDefinition = {
            labelText: { isLive: true },
            minimumDate: { isLive: true, isRequired: false }, // minimum date the user can choose
            maximumDate: { isLive: true, isRequired: false }, // maximum date the user can choose
            allowWeekends: { defaultValue: true },
            blackoutDates: { isLive: true, defaultValue: ko.observableArray() }, //array of dates user cannot choose(such as blackoutDates)
            value: { isLive: true, defaultValue: "" },
            pickerOnly: { isLive: true, defaultValue: true },
            dateFormat: { defaultValue: "mm/dd/yy" },
            timeFormat: { defaultValue: "h:mm tt" },
            type: { defaultValue: "datepicker" },
            options: { isLive: false, defaultValue: {} }
        }; //defaultValue: ko.observable(new Date()),

        c.validateValues = function (settings) {
            //ensure blackoutDates is an array
            settings("blackoutDates").throwIf().not().isArray();

            //for mindate check to see if its a valid date
            if (settings.minimumDate && settings.minimumDate()) {
                settings("minimumDate").throwIf().not().isDate();
            }

            //for maxdate check to see if its a valid date
            if (settings.maximumDate && settings.maximumDate()) {
                settings("maximumDate").throwIf().not().isDate();
            }

            settings("allowWeekends").throwIf().not().isOneOf([true, false]);

            settings("type").throwIf().not().isOneOf(['datepicker', 'datetimepicker']);

            //check for 'format' property
            settings("format").throwIf(function () {
                return settings.hasOwnProperty("format");
            }, "Property 'format' has been renamed to 'dateFormat'  Please use this new property name immediately.");

        }

        c.beforeBind = function ($markup, settings, bindingContext, $element) {
            if (settings.format) //[DD45578 - 5-1-18] Only do this if user attempts to use deprecated format property
            {
                //provide backwards compatibility for 'format' property
                settings.dateFormat = settings.format;
            }
        }

        c.afterDomInsert = function ($markup, settings, bindingContext, $element) {

            var options = $.extend({}, {
                minDate: settings.minimumDate(),
                maxDate: settings.maximumDate(),
                showOn: "both",
                buttonText: "<span data-icon='H'></span><i class='fa fa-calendar' />",
                beforeShowDay: checkDate,
                dateFormat: settings.dateFormat,

                //timepicker options
                timeFormat: settings.timeFormat,
                controlType: 'select',
                oneLine: true,
                showSecond: false,
                showMillisec: false,
                showMicrosec: false,
                showTimezone: false
            }, settings.options);

            var $input = $element.find("input");

            $input[settings.type](options);
            //when first setting the date, only set it, if it is a validate date
            if (!isNaN(Date.parse(settings.value()))) {
                $input[settings.type]("setDate", settings.value());
            }

            //init the validation binding.  This is necessary to get ko validation
            //  to operate correctly on this input, since it isn't ko bound like normal.
            //ko.bindingHandlers.validationCore.init($input[0], ko.observable((settings.value)));

            //handle the field changing by registering datepicker's changeDate event

            //handle the datepicker changing
            //TODO:  why doesn't ko.utils.registerEventHandler work?
            $input.change(function () {
                if (settings.skipUpdate) return;
                var val = $input.val();
                if (isNaN(Date.parse(val))) {
                    settings.value(void 0);
                    $input[settings.type]("setDate", void 0);
                } else {
                    settings.value(new Date(val));
                }
            });

            //handle the observable changing
            settings.value.subscribe(function (newValue) {
                //set date picker value to the date selected
                var value = newValue,
                    current = $input[settings.type]("getDate");

                //handle date data coming via json from Microsoft
                if (String(value).indexOf('/Date(') == 0) {
                    value = new Date(parseInt(value.replace(/\/Date\((.*?)\)\//gi, "$1")));
                }

                ////make sure field is formatted correctly.
                //var inst = $.data($input[0], "datepicker");
                //$.datepicker._setDate(inst, value, true);


                if (value - current !== 0) {
                    $input[settings.type]("setDate", value);
                }
            });

            settings.minimumDate.subscribe(function (newValue) {
                if ($utility.isDate(newValue)) {
                    $input[settings.type]("option", "minDate", newValue);

                }
                else {
                    $log.error("Minimum Date should be a Date object.");
                }
            });

            settings.maximumDate.subscribe(function (newValue) {
                if ($utility.isDate(newValue)) {

                    $input[settings.type]("option", "maxDate", newValue);
                }
                else {
                    $log.error("Maximum Date should be a Date object.");
                }

            });


            //handle disposal (if KO removes by the template binding)
            ko.utils.domNodeDisposal.addDisposeCallback($element[0], function () {
                $input[settings.type]("destroy");
            });


            //callback functions for the datepicker
            function checkDate(date) {

                var result = checkWeekends(date);

                if (result[0]) {
                    result = checkBlackoutDates(date);
                }

                return result;

            }

            function checkWeekends(dateSingle) {
                if (settings.allowWeekends) {
                    return [true, ''];
                }

                var day = dateSingle.getDay();

                if ((day > 0) && (day < 6)) {
                    return [true, ''];
                }
                return [false, ''];
            }

            function checkBlackoutDates(dateSingle) {
                //var and fill the noWeekendsOrblackoutDates
                var blackoutDates = ko.utils.unwrapObservable(settings.blackoutDates);
                if (blackoutDates) {
                    var currentDate;
                    for (var i = 0; i < blackoutDates.length; i++) {
                        currentDate = new Date(blackoutDates[i]);
                        if (dateSingle.getMonth() == currentDate.getMonth() && dateSingle.getDate() == currentDate.getDate() && dateSingle.getYear() == currentDate.getYear()) {
                            return [false, blackoutDates[i][2] + '_day'];
                        }
                    }
                }
                return [true, ''];
            }
        }

    });
});
