define('fusion/setup/knockout-rules',['require','fusion/log','knockout','ext/knockout.validation'],function (require)
{
    "use strict";


    var log = require("fusion/log");
    log.trace("Loaded setup/knockout-rules module");

    var ko = require("knockout");
    require('ext/knockout.validation');

    ;
    /*
     * Determines if a field is required or not based on a function or value
     * Parameter: boolean function, or boolean value
     * Example
     *
     * viewModel = {
     *   var vm = this;
     *   vm.isRequired = ko.observable(false);
     *   vm.requiredField = ko.observable().extend({ conditional_required: vm.isRequired});
     * }   
    */
    ko.validation.rules['conditional_required'] = {
        validator: function (val, condition)
        {
            var required = false;
            if (typeof condition == 'function')
            {
                required = condition();
            }
            else
            {
                required = condition;
            }

            if (required)
            {
                return !(val == undefined || val == null || val.length == 0);
            }
            else
            {
                return true;
            }
        },
        message: "Field is required"
    };





    // must be last so that all rules extenstions are applied
    ko.validation.registerExtenders();
});
