"use strict";
define('fusion/setup/knockout',['require','fusion/log','ext/ko.command','ext/knockout.validation','knockout','ext/ko.mapping','ext/knockout.extender.cookie','ext/knockout-date-bindings','ext/knockout.extender.bool','fusion/private/control-behavior-binding','fusion/utils','./knockout-rules','fusion/jquery','durandal/plugins/router','durandal/composition','durandal/viewLocator'],function (require) {

    var log = require("fusion/log");
    log.trace("Loaded setup/knockout module");

    require('ext/ko.command');
    require('ext/knockout.validation');
    var ko = require("knockout");
    ko.mapping = require('ext/ko.mapping');
    require('ext/knockout.extender.cookie');
    require('ext/knockout-date-bindings');
    require('ext/knockout.extender.bool');
    require('fusion/private/control-behavior-binding');
    var $utility = require("fusion/utils");

    require('./knockout-rules');

    var $ = require("fusion/jquery");

    var rootRouter = require('durandal/plugins/router');
    var composition = require('durandal/composition');
    var viewLocator = require('durandal/viewLocator');

    //a simple non-localized binding to format currency
    ko.bindingHandlers.currency = {
        update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {

            var val = ko.unwrap(valueAccessor());
            var formattedValue = $utility.formatCurrency(val);

            //forward to text binding so that it works correctly on virtual elements
            ko.bindingHandlers.text.update(element, new ko.observable(formattedValue), allBindings, viewModel, bindingContext);
        }
    };

    ko.virtualElements.allowedBindings.currency = true;

    //binding to format numbers
    ko.bindingHandlers.number = {
        update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {

            var val = ko.unwrap(valueAccessor());
            var isNegative = new Number(val) < 0;
            var numericVal = Math.abs(new Number(val)).toString();
            var formattedValue = "";
            if (numericVal !== "NaN") {

                if (isNegative) {
                    //prefix negative values with "minus" before the dollar sign
                    formattedValue += "-";
                };
                //add dollar sign and add thousands separator
                //needs to format numbers like "12345" as well as "12345.00"
                formattedValue += (numericVal.replace(/(\d)(?=(\d{3})+($|\.))/g, '$1,'));
            }

            //forward to text binding so that it works correctly on virtual elements
            ko.bindingHandlers.text.update(element, new ko.observable(formattedValue), allBindings, viewModel, bindingContext);
        }
    };

    ko.virtualElements.allowedBindings.number = true;

    // ADDED ** v6.0.5
    // This binding is essentially just a wrapper for the knockout html binding, except
    // that we re-apply bindings to the html. This allows the html to include data-bind
    // elements, which will be handled correctly.
    //
    // NOTE: We disable this as a virtual binding, since the html binding is not a virtual binding.
    ko.bindingHandlers.fusionBindHtml = {
        init: function () {
            // We will handle bindings of all descendants
            return { controlsDescendantBindings: true };
        },
        update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
            // Get binding value
            var value = ko.unwrap(valueAccessor());
            // Create child html
            ko.applyBindingsToNode(element, { html: value });
            // Apply bindings on html
            ko.applyBindingsToDescendants(bindingContext, element);
        }
    };
    ko.virtualElements.allowedBindings.fusionBindHtml = false;

    // ADDED ** v6.0.4
    // With ionicons now bundled with FX styles, this binding allows the user to use the new icons.
    // The reason we made this a binding is so that we can switch the icon (using css class) if we 
    // are in a cordova scenarion based on ios/android.
    ko.bindingHandlers.fusionIcon = {
        update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
            
            var _class = "fusion-icon icon ";
            var val = ko.unwrap(valueAccessor());

            // ionicons
            if (val.indexOf("ion-") >=0) {
                var iconName = val.substring(val.indexOf("-"));                                
                var iconClass = "";

                // If 'logo' icon, don't add platform specific class
                if (iconName.indexOf('logo') < 0) {
                    if (fusion.cordova.isCordova && fusion.cordova.platform.toLowerCase() === "ios") {
                        iconClass = "ios";   
                    } else { 
                        iconClass = "md";
                    }
                }

                _class += "ion-" + iconClass + iconName;
            } else {
                _class = val;
            }
            
            ko.bindingHandlers.css.update(element, new ko.observable(_class), allBindings, viewModel, bindingContext);
        }
    };

    /**
     ADDED ** v6.0.4

     Copied almost directly from durandal source code, but for a very specific reason. This binding came about
     as a result of developing the fusion-slideout control. The fusion-slideout control template includes a 'let'
     binding that passes down open/close/toggle functions to all descendant bindings by extending the binding 
     context. This works in view/viewModels, however it does not work in shell.js. This is because (generally)
     shell.js/.html contains the root router for the application. By default, durandal's router does NOT 
     pass down the parent binding to the child routes, but durandal's composition engine does allow for a 
     'preserveContext' flag to be set on compose bindings (which is basically all the 'router' binding is under
     the hood). However, this does not seem to work with fusion applications. After stepping through durandal's code
     this seems to be where the break down lies. The long and short of it is that when composition sees this flag set,
     it short circuits the composition process and loads the view/viewModel. However, the child is null when the binding is
     called, so this errors out. The FUSION CODE block below "eagerly" loads the view/viewModel pair so we don't null out, and
     allows us to pass the parent binding down through the router.
    
     Source code for router binding:
     https://github.com/BlueSpire/Durandal/blob/67e9e0bb3b49656f0eed76cccf2ef2da55b49433/src/plugins/js/router.js#L1103
    
     Explanation of 'preserveContext' flag:
     https://github.com/BlueSpire/Durandal/blob/734c0a79f5623ed319202c8b91c742e3751d77f1/docs/2.x/Using-Composition.html.md#preserve-context
    
     Composition line that breaks on normal router:
     https://github.com/BlueSpire/Durandal/blob/734c0a79f5623ed319202c8b91c742e3751d77f1/src/durandal/js/composition.js#L505
    */
    //ko.bindingHandlers.fusionRouter = {
    //    init: function () {
    //        return { controlsDescendantBindings: true };
    //    },
    //    update: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
    //        var settings = ko.utils.unwrapObservable(valueAccessor()) || {};

    //        if (settings.__router__) {
    //            settings = {
    //                model: settings.activeItem(),
    //                attached: settings.attached,
    //                compositionComplete: settings.compositionComplete,
    //                activate: false
    //            };
    //        } else {
    //            var theRouter = ko.utils.unwrapObservable(settings.router || viewModel.router) || rootRouter;
    //            settings.model = theRouter.activeItem();
    //            settings.attached = theRouter.attached;
    //            settings.compositionComplete = theRouter.compositionComplete;
    //            settings.activate = false;
    //        }

    //        // ** FUSION CODE ** //
    //        if (settings.preserveContext) {
    //            if (settings.model && theRouter.activeItem()) {                    
    //                settings.view = viewLocator.convertModuleIdToViewId(theRouter.activeItem().moduleId);
    //                settings.model = theRouter.activeItem();
    //            } else {
    //                settings.preserveContext = false;
    //            }
    //        }
    //        // ** FUSION CODE ** //

    //        composition.compose(element, settings, bindingContext);
    //    }
    //};
    //ko.virtualElements.allowedBindings.fusionRouter = true;

});
