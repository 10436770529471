define('fusion/ui/controls/fusion-link',["fusion/fusion.control"], function (ControlFactory) {
    return ControlFactory.control(function () {
        var m = this;

        m.settingsDefinition = {
            url: { isLive: true, defaultValue: "javascript:void(0)" }
        };

    });
});
