define('fusion/setup/durandal',['fusion/jquery', 'durandal/app', 'fusion/log', 'durandal/viewLocator', 'durandal/viewEngine' ,'durandal/binder', 'durandal/plugins/router', 'durandal/system', 'fusion/utils', 'fusion/event'],
    function ($, app, log, viewLocator, viewEngine, viewModelBinder, router, system, utils, $event) {
        "use strict";

        var globalSetupModule;

        log.trace("Loaded setup/durandal module");

        return globalSetupModule = {
            init: init
        };

        function init() {
            log.trace("inside durandalSetup.init()");

            return utils.defer(function (dfd) {

                system.debug(log.hasConsoleFlag(log.flags.debug));

                attachRouterEvents();

                //configure view model binder
                viewModelBinder.throwOnErrors = true;

                //add logging when durandal can't find a view
                configureViewEngine(viewEngine);

                //prevent Durandal from overwriting the document title
                app.title = null;

                //specify which plugins to install and their configuration
                app.configurePlugins(
                    {
                        router: true,
                        dialog: true
                    },
                    "durandal/plugins"
                );


                //configure view locator
                viewLocator.useConvention("viewModels", "views");
                viewLocator.translateViewIdToArea = function (viewId, area) {
                    return viewId;
                };

                log.debug("calling app.start()", utils.getCurrentLineNumber(), "durandal.js");
                app.start().done(function () {
                    log.debug("app.start() resolved", utils.getCurrentLineNumber(), "durandal.js");
                    dfd.resolve();
                })
                .fail(function () {
                    log.error("app.start() failed to resolve", utils.getCurrentLineNumber(), "durandal.js");
                });

            }).promise();
        }

        function configureViewEngine(viewEngine) {
            var oldFn = viewEngine.createFallbackView;
            //overwrite the createFallbackView function to add logging when durandal fails to find a view.
            viewEngine.createFallbackView = function (viewId, requirePath, err) {
                var message = 'View Not Found. Searched for "' + viewId + '" via path "' + requirePath + '".';
                message += " Error: " + err.message;
                if (err.xhr) {
                    message += " XHR Response: " + err.xhr.responseText;
                }
                log.error(message);

                //call original function
                return oldFn.apply(this, arguments);
            }
        }

        function attachRouterEvents() {

            var dtActionId,
                navigatingInstance;


            // attach router event to log analytic page view
            router.on("router:navigation:processing").then(function (instruction, router) {
                log.analytic("UI", "Route-Start", "/#" + instruction.fragment);                
                $event.publish("routeChange",  {"eventName": "Route-Start", "routeObject": instruction.fragment });
            });
            router.on("router:navigation:complete").then(function () {
                log.analytic("UI", "Route-Complete", window.location.href);
                $event.publish("routeChange",{"eventName": "Route-Complete", "routeObject": window.location.href });
            });
            router.on("router:navigation:cancelled").then(function (instance, instruction, router) {
                log.analytic("UI", "Route-Cancel", "/#" + instruction.fragment);
                $event.publish("routeChange", {"eventName": "Route-Cancel", "routeObject": instruction.fragment });
            });
            router.on("router:navigation:redirect").then(function (url) {
                log.analytic("UI", "Route-Redirect", url);
                $event.publish("routeChange", {"eventName": "Route-Redirect", "routeObject": url });
            });



            //attach router events for dynatrace logging
            router.on("router:route:activating").then(enterNavigationAction);
            router.on("router:navigation:cancelled").then(leaveNavigationAction);
            router.on("router:navigation:redirected").then(function () {
                leaveNavigationAction(navigatingInstance);
            });
            router.on("router:navigation:composition-complete").then(leaveNavigationAction);

            function enterNavigationAction(instance, instruction, router) {

                var action = "Navigate to " + instruction.fragment;

                //when navigating, end current action
                if (dtActionId) {
                    //report event, since there shouldn't be an open action
                    dynaTrace.reportEvent("aborting open action");
                    //leave the current action
                    leaveNavigationAction(navigatingInstance);
                }

                dtActionId = dynaTrace.enterAction(action, "Load", null);
                //set this so that we know when this particular instance has completed composition.
                navigatingInstance = instance;

            }

            function leaveNavigationAction(instance) {
                if (dtActionId && instance === navigatingInstance) {
                    //end navigation action
                    dynaTrace.leaveAction(dtActionId);


                    dtActionId = null;
                }
            }
        }       
    });
