"use strict";
define('fusion/setup/framework',['require','fusion/log','fusion/jqueryui','fusion/log-collector','fusion/jquery','./knockout','./polyfill','fusion/fusion.control','fusion/fusion.module','fusion/private/fusion-control','fusion/data','ext/json.date-extensions','knockout','fusion/config','fusion/event','foundationlib'],function (require) {

    var log = require("fusion/log");
    log.trace("Loaded setup/framework module");

    //load additional modules
    require("fusion/jqueryui");
    require("fusion/log-collector");
    var $ = require("fusion/jquery");
    require("./knockout");
    require("./polyfill");
    require("fusion/fusion.control");
    require("fusion/fusion.module");
    require("fusion/private/fusion-control");
    require("fusion/data");
    require("ext/json.date-extensions");
    JSON.useDateParser();


    // Added v6.0.4 - making isOnline observable
    var ko = require("knockout");

    var $config = require("fusion/config");


    // Added v6.0.11 to set up dark mode event publish by Fx
    var $event = require("fusion/event");

    //#region dark mode indicator
    if (window.matchMedia) {
        var darkModeIndicator = window.matchMedia('(prefers-color-scheme: dark)');

        toggleDarkTheme(darkModeIndicator.matches);

        // listen for changes to the prefers-color-scheme media query
        darkModeIndicator.addListener(function (mediaQuery) {
            toggleDarkTheme(mediaQuery.matches);
        });


        function toggleDarkTheme(shouldAdd) {

            $event.publish("darkMode", shouldAdd);

        }
    }

    //#endregion dark mode indicator





    // Runs when module is required
    //scroll To plugin
    $.fn.scrollTo = function (target, options, callback) {
        if (typeof options == 'function' && arguments.length == 2) { callback = options; options = target; }
        var settings = $.extend({
            scrollTarget: target,
            offsetTop: 50,
            duration: 300,
            easing: 'swing'
        }, options);
        return this.each(function () {
            var scrollPane = $(this);
            var scrollTarget = (typeof settings.scrollTarget == "number") ? settings.scrollTarget : $(settings.scrollTarget);
            var scrollY = (typeof scrollTarget == "number") ? scrollTarget : scrollTarget.offset().top + scrollPane.scrollTop() - parseInt(settings.offsetTop);
            scrollPane.animate({ scrollTop: scrollY }, parseInt(settings.duration), settings.easing, function () {
                if (typeof callback == 'function') { callback.call(this); }
            });
        });
    }

    function loadIcons() {
        return new Promise(function (resolve, reject) {

            require(["css!" + fusion.scripts + "icons/ionicons.min.css"], resolve, resolve);

        });
    }


    /**
    * Added ** v6.0.4
    * Setting observability for fusion.isOnline
    */
    fusion.isOnline = ko.observable(navigator.onLine || false);

    // events to update 
    window.addEventListener('offline', function (e) { fusion.isOnline(false); });
    window.addEventListener('online', function (e) { fusion.isOnline(true); });





    /**
     * ADDED ** v6.0.0
     * Returns a promise that resolves once enterprise styles are required.
     * This was put in its own function so that we can control whether we load these
     * styles. In particular, we need to load the isEnterprise from framework.init() 
     * first before we can make a decision as to whether we require the enterprise styles.
     */
    function loadEnterpriseStyles() {
        return new Promise(function (resolve, reject) {
            require(["css!" + fusion.scripts + "css/scana-enterprise.css"], resolve, reject);
        });
    }

    /**
     * MODIFIED ** v6.0.0
     * Loads the style frameworks, specifically foundation. This was made its own function
     * so that we can control the load order. Foundation cannot initialize correctly until 
     * either enterprise or sbu-specific styles have been loaded.
     */
    function loadStyleFrameworks() {
        return new Promise(function (resolve, reject) {
            require("foundationlib");
            $(document).ready(function () {

                // SH46000 - 8/6/18
                // Had to wrap this in a promise becuase of 
                // move to bundling. Ran into issue where require resolved
                // the css module load but the network request was still pending.
                fusion.isFoundationReady(false).then(function () {
                    $(document).foundation();
                });


                //#region OffCanvasHandlers
                /*********************************************************************************************************************************
                * Region: Off-Canvas Menu Special Handlers
                * Description: This block of code will handle the animation of the menu in response to the position of the off-canvas menu
                * Requirements: This script block must be included on each page utilizing the off-canvas menu
                *********************************************************************************************************************************/
                if (Modernizr.csstransforms3d) {
                    $(".right-off-canvas-menu").on('transitionend webkitTransitionEnd oTransitionEnd otransitionend MSTransitionEnd',
                        function (e) {
                            if (e.target.className === "right-off-canvas-menu") {
                                shiftMenu();
                            }
                        }
                    );
                }
                else if (Modernizr.csstransforms) {
                    $(".right-off-canvas-toggle").click(function (e) {
                        // if (e.target.className === "menu" || e.target.className.indexOf("menu-icon") >= 0 || e.target.className === "hamburger-icon" || e.target.className === "close-icon") {

                        setTimeout(shiftMenu, 350);
                        //}
                    });
                }

                function shiftMenu() {
                    try {
                        var margn = -8;
                        var targetRight = Math.ceil($(".menu-group").offset().left) + Math.ceil($(".menu-group").width());
                        var flyoutLeft = Math.ceil($(".right-off-canvas-menu").offset().left);
                        var flyoutWidth = Math.ceil($(".right-off-canvas-menu").width());
                        var bodyWidth = Math.ceil($("body").width());


                        if (flyoutLeft >= bodyWidth) {

                            if (Modernizr.csstransforms3d) {
                                $(".menu-group").animate({ "padding-right": "0" });
                            }
                            else {

                                $(".menu-group").css("padding-right", "0");
                            }
                        }
                        else {

                            if (flyoutLeft <= (targetRight + margn)) {
                                if (Modernizr.csstransforms3d) {

                                    $(".menu-group").animate({ "padding-right": (targetRight + margn - flyoutLeft) + "px" });
                                }
                                else {

                                    $(".menu-group").css("padding-right", (targetRight + margn - flyoutLeft) + "px");
                                }
                            }
                        }
                    }
                    catch (e) {
                        log.error(e.message);
                    }
                }
                //#endregion OffCanvasHandlers

                //resolve();
            });

            resolve();
        });
    }

    function loadDefault() {
        return new Promise(function (resolve) {

            $.when(
                loadStyleFrameworks(),
                loadIcons()
            ).always(resolve);
        });
    }


    return new function () {

        var self = this;

        self.init = function () {
            return $config.loadFromPage();
        };

        /**
         * MODIFIED ** v6.0.0
         * Since self.init() should have been called before this function,
         * and thus isEnterprise flag set, we make a decision here about
         * whether to require enterprise styles. 
         */
        self.loadStyleFrameworks = function () {

            return new Promise(function (resolve, reject) {
                if (window.fusion.globals.isEnterprise) {
                    loadEnterpriseStyles()
                        .then(loadDefault)
                        .then(resolve, reject);
                } else {
                    loadDefault()
                        .then(resolve, reject);
                }
            })
        };

        self.finalize = function () {
            fusion.isLoaded = true;
        };

    }();

});
