define('fusion/service',["require", "fusion/log", "fusion/utils"], function (require, $log, utils) {
    "use strict";

    //this module provides a way to register custom services, that can be loaded through the normal service mechanism

    return new (function $Service() {

        var m = this;

        m.register = function (serviceName, service) {

            $log.trace("$Service.register");
   //         $log.debug("serviceName: " + serviceName, utils.getCurrentLineNumber(), "service.js");
            //$log.debug("service: " + service.toString(), utils.getCurrentLineNumber(), "service.js");
   //         $log.debug("service: " + service.moduleId, utils.getCurrentLineNumber(), "service.js");

            $log.debug("serviceName: " + serviceName + " | service : " + service.moduleId, utils.getCurrentLineNumber(), "service.js");

            if (!m[serviceName]) {
                m[serviceName] = service;
            } else {
                throw new Error("Service " + serviceName + " is already registered.");
            }
        };

    })();

});
