define('fusion/ui/controls/fusion-kendogrid',[
    "fusion/fusion.control",

    "ext/kendo/js/kendo.grid.min",
    "css!js/ext/kendo/styles/kendo.default.min.css",
    "css!js/ext/kendo/styles/kendo.common.min.css"
], function (ControlFactory) {

    return ControlFactory.control(function (require, $, $log, ko) {
        var c = this;

        //load kendo grid resources
        //require("ext/kendo/js/kendo.grid.min");
        //require("css!ext/kendo/styles/kendo.default.min.css");
        //require("css!ext/kendo/styles/kendo.common.min.css");

        c.settingsDefinition = {
            observableData: { isRequired: false, isLive: true },
            kendoOptions: { isRequired: false, isLive: false },
            autoFitColumns: { isRequired: false, isLive: false, defaultValue: false },
            autoFitHeight: { isRequired: false, isLive: false, defaultValue: true },
            //kendoGrid: { isRequired: false, isLive: true },
            onRowClicked: { isRequired: false, isLive: false },
            autoPaginationReset: { isRequired: false, isLive: false, defaultValue: true }
        }

        var defaultKendoOptions = {
            scrollable: true,
            pageable: true,
            selectable: false,
            resizable: true,
            sortable: true,
            filterable: { extra: false },
            height: '100%',
            width: '100%'
        }

        //c.beforeBind = function ($markup, settings, bindingContext, $element) {
        //}

        c.afterDomInsert = function ($markup, settings) { //, bindingContext, $element) {

            var $gridContainer = $markup;

            var kOptions = $.extend({},
                defaultKendoOptions,
                settings.kendoOptions); //specify dataSource in kendoGridOptions to overwrite the default created above

            //wrap the databound event, while preserving existing handler or no-op 
            kOptions.dataBound = onDataBound.bind(null, kOptions.dataBound || function () { }, $gridContainer);

            //add grid target to container
            var $gridTarget = $("<div class='grid-target'/>");
            $gridContainer.append($gridTarget);

            $gridTarget.kendoGrid(kOptions);
            var widget = $gridTarget.data("kendoGrid");


            //add click event handler to support onRowClicked callback function
            if (settings.onRowClicked) {
                $gridTarget.on("click", " tbody > tr", function () {

                    var isDetailRow = $(this).parents("tr.k-detail-row").length > 0;          // determining if the clicked row is a detail row, note length will be >1 if this is a nested detail row
                    var nestingLevel = $(this).parents("tr.k-detail-row").length;             // determining depth of nesting on the clicked row

                    //setting the grid widget to be the closest grid to the row that was selected
                    //var gridWidget = $(this).closest("div.k-grid").data("kendoGrid");
                    var gridWidget = $(event.srcElement).closest("div.k-grid").data("kendoGrid");



                    var rowDataItem = null;
                    // try is used here since it is possible that a detail row can be present without data.  if so the rowDataItem assignment fails - error is caught and essentially the grid control does nothing
                    try {
                        rowDataItem = gridWidget.dataItem($(this));
                        if (rowDataItem) {
                            var kendoRowObject = gridWidget.dataSource.getByUid(rowDataItem.uid);
                            var originalRowDataItem = kendoRowObject.toJSON();

                            //calling onRowClicked with row data passed in
                            settings.onRowClicked(originalRowDataItem, isDetailRow, nestingLevel);
                        }
                    }
                    catch (e) {
                    }

                    //                return false;       // a stop propagation mechanism -- otherwise when a detail row is clicked, a master row is returned as well
                });
            }


            //set the kendoGrid object so that it can be accessed by dev in view model
            settings.kendoOptions.api = widget;


            if (settings.autoFitHeight) {
                $(window).on("resize." + settings.instanceId, resizeGridHeight.bind(null, $gridContainer, true));
            }

            //create a computed to monitor settings.data property an respond to its observability
            //use the throttle extender to limit how frequently this is called.
            if (ko.isObservable(settings.observableData)) {
                ko.computed({
                    read: function () {
                        var dataArray = settings.observableData();
                        console.log("set datasource data");
                        widget.dataSource.data(dataArray);
                        widget.refresh();
                        //if (dataArray.length === 0) {
                        //Note this will only execute when the array length === 0 at time of "idle" due to throttling
                        if (settings.autoPaginationReset == true) {
                            widget.dataSource.page(1);
                        }
                        //}
                    },
                    disposeWhenNodeIsRemoved: $gridTarget[0]
                }).extend({ throttle: 1 });
            }


            //clean up and dispose events/subscriptions when node is removed
            ko.utils.domNodeDisposal.addDisposeCallback($gridTarget[0], function () {
                $(window).off("resize." + settings.instanceId)
                widget.destroy();
            });

        }


        function resizeGridHeight($gridContainer, windowResized) {
            var widget = $gridContainer.find(".grid-target").data("kendoGrid");
            if (widget) {

                //when resizing the window, shrink entire container to smallest possible size 
                //  so that subsequent calculations are correct .
                // This is necessary when window is sized to a smaller size.
                if (windowResized) {
                    $gridContainer.height($("div.k-grid-header").height());
                }

                var footerHeight = $("footer").outerHeight(true);
                var targetHeight = $(window).height() - $gridContainer.offset().top - footerHeight;

                if ($gridContainer.height() !== targetHeight) {

                    // Use this information for debugging resize issues
                    //$log.trace("RESIZING GRID");
                    //$log.trace("==============================");
                    //$log.trace(`FOOTER HEIGHT: ${footerHeight}`);
                    //$log.trace(`WINDOW HEIGHT: ${$(window).height()}`);
                    //$log.trace(`DOCUMENT HEIGHT: ${$(document).height()}`);
                    //$log.trace(`GRID CONTAINER offset().top: ${$gridContainer.offset().top}`);
                    //$log.trace(`NEW GRID HEIGHT: ${targetHeight}`);


                    //resize the kendogrid according to its container
                    $gridContainer.stop().animate(
                        { height: targetHeight },
                        { progress: function () { widget.resize(); }, duration: 'fast' });
                }
            }
        }

        function onDataBound(next, $gridContainer, e) {
            //console.log("data bound " + $gridContainer.attr("id"));
            resizeGridHeight($gridContainer, false);
            void e;
            next();
        }
    })
});
