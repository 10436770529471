define('fusion/ui/controls/fusion-container',["fusion/fusion.control"], function (ControlFactory) {

    return ControlFactory.control(function (/*$, ko, $log*/) {
        var c = this;

        c.settingsDefinition = {
            isBusy: { isLive: true, defaultValue: false },
            busyMessage: { isLive: true, defaultValue: null },
            shouldCoverHeader: {isLive: false, defaultValue: false}
        };

        c.validateValues = function (settings) {
        }

        c.beforeBind = function ($markup, settings, bindingContext, $element) {
        }

        c.afterBind = function ($markup, settings, bindingContext, $element) {
        }

        c.afterDomInsert = function ($markup, settings, bindingContext) {
        }
    });

});
