define('fusion/ui/controls/fusion-list-item',["fusion/fusion.control"], function (ControlFactory) {
    return ControlFactory.control(function (ko, $) {
        var c = this;

        c.settingsDefinition = {
        };

        c.validateValues = function (settings) {
        }

        c.beforeBind = function ($markup, settings, bindingContext, $element) {
        }

        c.afterBind = function ($markup, settings, bindingContext, $element) {
        }

        c.afterDomInsert = function ($markup, settings, bindingContext) {
            var i = 0;
            if (bindingContext.$parentControl.isZebraStriped) {
                $markup.closest("ul").children("li").each(function (index, el) {
                    $(el).removeClass("even odd");
                    if (i++ % 2) {
                        $(el).addClass("even");
                    } else {
                        $(el).addClass("odd");
                    }
                });
            }
        };

    });

});
