define('fusion/message',["require", "fusion/jquery", "fusion/log", "fusion/utils"], function (require, $, $log, utils) {
    "use strict";

    return new (function $Message() {

        var m = this;

        m.getMessages = function (propertyPath) {

            $log.trace("$Message.getMessages");
            $log.debug("propertyPath: " + propertyPath, utils.getCurrentLineNumber(), "message.js");

            return new Promise(function (resolve, reject) {
                require(["fusion/data"], function ($data) {
                    var params = propertyPath ? { propertyPath: propertyPath } : null;
                    $data.get("GetMessages", "MessagesApi", params, { cache: true })
                    .done(function (result) {

                        $log.debug("GetMessages result: " + JSON.stringify(result), utils.getCurrentLineNumber(), "message.js");

                        $.extend(m, result);
                        resolve();
                    })
                    .fail(reject);
                });
            });
        };

    })();

});
