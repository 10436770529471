define("fusion/validation", ['require','knockout','ext/knockout.validation'],function (require) {
    "use strict";
    var ko = require("knockout");
    require('ext/knockout.validation');

    //set default configuration options.  See : https://github.com/Knockout-Contrib/Knockout-Validation/wiki/Configuration
    // These are required in order for the webModValidation styling to work correctly
    ko.validation.init({
        decorateInputElement: true,
        errorClass: "fusion-error",
        errorsAsTitle: false    //turn off messages in tooltip
    });


    function validateViewModel(module) {
        /// <signature>
        /// <summary>Validates view model and display all remaining errors</summary>
        /// <param name="module" type="Object">The module to be validated</param>
        /// <returns type="Boolean" />
        /// </signature>

        if (!module) {
            throw new Error("`module` parameter cannot be null.  It must be an object to validate.");
        }

        //TODO: Modify to only shallow validation, but do it for all children recursively.
        var errors = ko.validation.group(module, { deep: true });
        if (errors().length === 0) {
            return true;
        }
        else {
            //Changed validation to send "forceValidate" to showAllMessages rather than true - gives a little more granulairty in validation controls.  
            // Used in webmod-password currently to as a way to trigger confirm value validation
            errors.showAllMessages("forceValidate");
            return false;
        }
    }

    return {
        validateViewModel: validateViewModel
    };
});
