"use strict";
define('fusion/ui/controls/fusion-expander-panel',["fusion/fusion.control"], function (ControlFactory) {
    return ControlFactory.control(function ($, ko, $log) {
        var m = this;

        var $root = $('html, body');

        m.settingsDefinition = {
            text: { isLive: true, isRequired: false },
            subText: { isLive: true },
            isExpanded: { isLive: true, defaultValue: false }
        };


        m.validateValues = function (settings) {
        };

        m.afterBind = function ($markup, settings, bindingContext, $element) {
        };

        m.afterDomInsert = function ($markup, settings, bindingContext) {

            var $expander = $markup.closest(".fusion-expander");
            var mode = bindingContext.$parentControl.mode;
            var $expanderPanel = $markup;

            var scrollIntoView = bindingContext.$parentControl.scrollIntoView;      // note that setting of 'false' ( string val ) will be treated as true in this case

            //connect the parent control's expandedPanels property
            applyExpandedPanelsLogic(settings.isExpanded, bindingContext.$parentControl.expandedPanels, $markup);

            //set up close/open events that are used to implement the open/close functionality.
            $expanderPanel.on("close", function () {
                expanderClose($expanderPanel);
            });

            $expanderPanel.on("open", function () {
                //collapse all other panels if in 'accordion' mode
                if (mode === "accordion") {
                    closeOtherPanels($expander, $expanderPanel);
                }
                expanderOpen($expanderPanel, scrollIntoView());
            });

            //set up a computed to open and close accordion when the observable value changes
            ko.computed(function () {
                $expanderPanel.trigger(settings.isExpanded() ? "open" : "close");
            });

            //set up click handler on the expander header
            var $a = $expanderPanel.find('a').first();
            $a.click(function () {
                //toggle logic (only when clicking)
                var $el = $expanderPanel.children('.content');
                $expanderPanel.trigger($el.is(':visible') ? "close" : "open");

                return false;
            });

        };

        function expanderOpen($panel, scrollToView) {

            var ce = $panel.children('a'),
                el = $panel.children('.content'),
                controlSettings = ko.dataFor($panel[0]),
                scrollIntoView = scrollToView;


            controlSettings.isExpanded(true);

            el.slideDown(function () {
                if (scrollIntoView) {
                    $root.animate({
                        scrollTop: $(ce).offset().top - $(ce).outerHeight()
                    });
                }
            });

            el.parent('dd').addClass('active');
            el.addClass('active');
        }

        function expanderClose($panel) {
            var ce = $panel.children('a'),
                el = $panel.children('.content'),
                controlSettings = ko.dataFor($panel[0]);

            controlSettings.isExpanded(false);

            el.slideUp();
            el.parent('dd').removeClass('active');
            el.removeClass('active');
        }

        function closeOtherPanels($expander, $current) {
            $expander
                .find('.fusion-expander-panel')
                .not($current)
                .find(".content")
                .each(function () {
                    //don't trigger this element to close as part of sweep.  Let other logic handle this
                    $(this).trigger("close");
                });
        }

        function applyExpandedPanelsLogic(isExpanded, expandedPanels, $markup) {

            //monitor isExpanded and set expandedPanels accordingly.
            //Note: this only fires for this panel, since isExpanded belongs to this panel
            isExpanded.subscribe(function (newValue) {

                //get index of this panel within the expander control
                var index = getPanelIndex($markup);

                if (newValue) {
                    //add to expandedPanels array if not already there
                    if (expandedPanels().indexOf(index) === -1) {
                        $log.trace("adding panel " + index + " to expandedPanels");
                        expandedPanels.push(index);
                    }
                } else {
                    $log.trace("removing panel " + index + " from expandedPanels");
                    expandedPanels.remove(index);
                }
            });

            //monitor expandedPanels array to close or open specified panels
            //Note: this fires for each panel, so make sure only process items related to this panel
            expandedPanels.subscribe(function (newValue) {
                //get index of this panel within the expander control
                var index = getPanelIndex($markup);

                $log.trace("panel " + index + " processed expandedPanels subscribe");

                //if this panel's index is not in new array, then set isExpanded to false if it is currently expanded
                if (expandedPanels().indexOf(index) === -1 && isExpanded()) {
                    isExpanded(false);
                } else
                    //if this panel's index IS in new array, then set isExpanded to true if it is currently NOT expanded
                    if (expandedPanels().indexOf(index) > -1 && !isExpanded()) {
                        isExpanded(true);
                    }
            });

        }

        function getPanelIndex($markup) {
            return $markup.index();
        }

    });
});
