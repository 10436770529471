define('fusion/ui/controls/fusion-linkexpander',["fusion/fusion.control"], function (ControlFactory) {
    return ControlFactory.control(function ($, ko, require) {
        var c = this;

        c.settingsDefinition = {
            mode: { isLive: false, defaultValue: 'expander' },
            scrollIntoView: { isLive: true, defaultValue: false }
        };

        c.validateValues = function (settings) {
            settings("mode").throwIfNot().isOneOf(['expander']);
        };

        c.beforeBind = function ($markup, settings, bindingContext, $element) {
        };

        c.afterDomInsert = function ($markup, settings, bindingContext, $element) {

        };

    });
});
