"use strict";
define('fusion/fusion.module',['require','fusion/log','./factory-helper','module','fusion/private/module-base'],function (require) {

    var $log = require("fusion/log");
    var factoryHelper = require("./factory-helper");

    window.fusion.module = function (persist, f, moduleId, isCalledBySharedModule) {

        var isPersistWarningMessageNeeded = false;

        f = f || persist;

        if (!isCalledBySharedModule && (persist === fusion.persist)) {
            //    $log.warning("fusion.persist has been deprecated.  Use fusion.sharedModule(function(){...}) instead.");
            isPersistWarningMessageNeeded = true;
        }

        //parse out an array of dependencies from the factory function
        var deps = factoryHelper.parseDependencies(f);
        //parse out an array of the argument names that the function receives
        var args = factoryHelper.argumentNames(f);

        //if dependencies found, this means the developer is calling `require` within the module
        //In this case ,we need to ensure that the developer also specifies require as an argument.
        //  Otherwise, there are edge cases where window.require doesn't behave the same.
        //  One example is when requiring relative resources.
        var requireServiceNotRequested = false;
        if (deps.length > 0 && args.indexOf("require") === -1) {
            requireServiceNotRequested = true;
        }

        //for view model, we know we'll need the base view model, so require it, 
        //  because requireJS won't parse this file.
        deps.push("fusion/private/module-base");

        //finally, insert the "require" dependency at the beginning of the array
        //  so that we have access to it as the first argument in the upcoming "define" call
        deps.unshift("require");

        //this is the function that creates the fusion.module
        var moduleFactory = function (require) {

            //if moduleId not supplied, then try to get it from require
            if (!moduleId) {
                var module = require("module");
                moduleId = module.id;
            }

            // check for persist warning message
            if (isPersistWarningMessageNeeded) {
                $log.warning("fusion.persist has been deprecated.  Use fusion.sharedModule(function(){...}) instead for " + moduleId);
                // resetting 
                isPersistWarningMessageNeeded = false;
            }


            //throw violation error here, so that we are inside the define, and Durandal can get the module id as part of the error description.
            if (requireServiceNotRequested) {
                throw new Error("Error loading module: " + moduleId + ".  When calling `require()`, the `require` service must also be specified in the module's argument list.");
            }

            //create a view model function
            var FusionModule = function () {

                //create a list of services that are specified by arguments
                var services = factoryHelper.getServices(args, require, this);

                f.apply(this, services);
            };

            var Base = require("fusion/private/module-base");
            FusionModule.prototype = new Base(moduleId);
            FusionModule.prototype.constructor = f;

            //if `persist` is specified, then return a "singleton" object; 
            //  otherwise return a constructor function
            if (persist === fusion.persist) {
                return new FusionModule();
            } else {
                return FusionModule;
            }
        };

        //if moduleId is present, then don't define an AMD module; just return the factory result immediately
        //  This is useful when dynamically calling fusion.module such as in fusion-example.js
        if (moduleId) {
            return moduleFactory(require, moduleId);
        } else {
            define(deps, moduleFactory);
        };

    }

    //better syntax for shared (persistent) module
    window.fusion.sharedModule = function (f, moduleId) {
        window.fusion.module(fusion.persist, f, moduleId, true);
    }

    return {
        module: window.fusion.module,
        sharedModule: window.fusion.sharedModule
    };
});
