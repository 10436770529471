define("fusion/private/ajax", ['require','fusion/private/notice','fusion/utils','knockout','fusion/jquery','fusion/log'],function (require) {
    "use strict";

    var notice = require("fusion/private/notice");
    var utils = require("fusion/utils");
    var ko = require("knockout");
    var $ = require("fusion/jquery");
    var $log = require('fusion/log');

    //clean the data
    function cleanData(data, method) {

        var cleanData = data;

        if (cleanData instanceof Object) {
            //ensure it's not knockout object (this will unwrap observables and convert to pure JS or JSON)
            if (method === "POST") {
                cleanData = ko.toJSON(cleanData);
            } else {
                cleanData = ko.toJS(cleanData);
            }
        }

        //finished cleaning; return the data
        return cleanData;
    }


    function invokeAction(action, controller, server, data, method, options) {

        options = options || {};

        //default options
        var defaultAjaxOptions = {
            dataType: "json",
            contentType: "application/json",
            cache: false,
            traditional: true,
            async: true,
            beforeSend: function (xhr) {
                xhr.setRequestHeader("IsAjax", "true");
            }
        };
        server = server || "/";

        ////if server starts with 'http' then assume jsonp (only valid for GET requests)
        //if (server.indexOf("http") === 0) {
        //    if (method.toLowerCase() === "get") {
        //        defaultAjaxOptions.dataType = 'jsonp';
        //        defaultAjaxOptions.crossDomain = true;
        //    } else {
        //        throw new Error("Server settings can only be set for GET requests");
        //    }
        //}


        // if AFT is enabled, then get the AFT value and append to the header
        if (fusion.globals.isAftEnabled) {
            // on requests, automatically pull the __RequestVerificationToken from the DOM and marge it with the default ajax options
            var token = $('input[name="__RequestVerificationToken"]').val();

            if (!utils.isNullOrEmpty(token)) {
                //uncomment the following line to test the antiforgery token enforcement
                //token = "sdfkljasdfjklasd";
                defaultAjaxOptions = $.extend({}, defaultAjaxOptions, { headers: { __RequestVerificationToken: token } });
            }
            else {
                // token is missing
                var msg = "AFT is enabled but the AFT token is missing - check your master page to be sure it is included";
                $log.error(msg);
                throw msg;
            }
        }


        //caller-specified options
        var callerSpecifiedOptions = {};

        if (arguments.length === 1) {
            //if only one argument, then assume options argument is supplied
            callerSpecifiedOptions = arguments[0];
        } else {
            //build options from arguments
            var optionsFromArguments = {
                url: utils.url.ensureTrailingSlash(server || "")
                    + "fusionapi/" + utils.url.ensureTrailingSlash(controller)
                    + utils.url.ensureTrailingSlash(action),
                data: data,
                type: method
            };
            // set callerSpecifiedOptions to the options built from arguments unless a non-empty options object is passed in
            //  in which case the options from arguments are extended with those passed in
            callerSpecifiedOptions = ($.isEmptyObject(options)) ? optionsFromArguments : $.extend({}, options, optionsFromArguments);
        }

        //clean the data
        callerSpecifiedOptions.data = cleanData(callerSpecifiedOptions.data, callerSpecifiedOptions.type);

        //combine the settings into single options object
        var ajaxOptions = $.extend({}, defaultAjaxOptions);
        var ajaxOptions = utils.mergeDeep(ajaxOptions, callerSpecifiedOptions);

        //perform ajax call
        return $.ajax(ajaxOptions);

    }

    //return module
    var ajax = {
        invokeAction: invokeAction
    };

    return ajax;
});
