//TODO: need a way to remove messages when they are no longer in effect.  
//      Possibly by providing the ability to establish a notification context, 
//      which can then be referenced to clear all notifications created during that context.
//  A simpler option is to return the newly created notification from the add method.  The caller can track and erase as desired
//TODO: need to prevent too many messages - maybe enforce a max
define("fusion/private/notice", ['require','knockout'],function (require) {
    "use strict";
    var scope = {
        page: "page",
        global: "global"
    };

    var ko = require('knockout');
    var _notifications = ko.observableArray([]);
    var _pageNotifications = ko.computed(function () {
        //filter to only return page notifications
        return ko.utils.arrayFilter(_notifications(), function (item) {
            return item.scope == scope.page;
        });
    });
    var _globalNotifications = ko.computed(function () {
        //filter to only return global notifications
        return ko.utils.arrayFilter(_notifications(), function (item) {
            return item.scope == scope.global;
        });
    });

    _pageNotifications.add = function (msg) {
        add({
            message: msg,
            scope: "page"
        });
    }

    function add(settings) {
        //add entry to top of the list
        _notifications.unshift(
            {
                message: settings.message,
                scope: settings.scope
            });
    }

    return {
        scope: scope,   //enum
        add: add,
        remove: _notifications.remove,
        clear: function (scope) {
            if (scope) {
                throw new Error("Not implemented");
            } else {
                throw new Error("Not implemented");
                _notifications.removeAllExceptForGlobal();
            }
        },
        pageNotifications: _pageNotifications,
        globalNotifications: _globalNotifications
    };
});
