define('fusion/ui/controls/fusion-radiogroup-item',["fusion/fusion.control"], function (ControlFactory) {
    return ControlFactory.control(function ($, ko) {
        var c = this;


        c.settingsDefinition = {
            text: { isLive: true, isRequired: false },
            value: { isLive: true, isRequired: false, defaultValue: "" }
        };

        c.validateValues = function (settings) {
        }

        c.beforeBind = function ($markup, settings, bindingContext, $element) {
            if ($element.children("header").length > 0) {
                if (settings.text()) {
                    throw new Error("Error with fusion-radiogroup-item: Cannot specify both `text` property and a `<header>` element.");
                }
            }



        }

        c.afterBind = function ($markup, settings, bindingContext, $element) {
        }

        c.afterDomInsert = function ($markup, settings, bindingContext) {

            var $input = $markup.find("input");
            var $additionalContent = $markup.find(".radio-item-additional-content");

            if (bindingContext.$parentControl.contentMode === "expander") {
                $additionalContent.hide();
                bindingContext.$parentControl.selectedValue.subscribe(checkAdditionalContent);
                $input.change(checkAdditionalContent);


                function checkAdditionalContent() {
                    if (!$additionalContent.is(':empty')) {
                        if ($input.is(':checked')) {
                            $additionalContent.slideDown('fast').fadeIn('fast');
                        } else {
                            $additionalContent.slideUp('fast').fadeOut('fast');
                        }
                    }
                }

                //initial check
                checkAdditionalContent();
            }

        }

    });
});
