define('fusion/ui/controls/fusion-timepicker',[
    "fusion/fusion.control",
    "css!js/ext/jquery-timepicker.css",
    "ext/jquery-timepicker"
], function (ControlFactory) {

    return ControlFactory.control(function ($, ko, require) {
        var c = this;

        //require("css!ext/jquery-timepicker.css");
        //require("ext/jquery-timepicker");

        c.settingsDefinition = {
            labelText: { isLive: true },
            value: { isLive: true, defaultValue: "" },
            options: { isLive: false, defaultValue: {} }
        };

        c.validateValues = function (settings) {
        }

        c.beforeBind = function ($markup, settings, bindingContext, $element) {
        }

        c.afterDomInsert = function ($markup, settings, bindingContext, $element) {

            var options = $.extend({}, {
                //timepicker options
                timeFormat: 'HH:mm',
                controlType: 'select',
                oneLine: true,
                showSecond: false,
                showMillisec: false,
                showMicrosec: false,
                showTimezone: false
            }, settings.options);

            var $input = $element.find("input");

            $input.timepicker(options);

            //init the validation binding.  This is necessary to get ko validation
            //  to operate correctly on this input, since it isn't ko bound like normal.
            ko.bindingHandlers.validationCore.init($input[0], ko.observable((settings.value)));

            //handle the field changing by registering datepicker's changeDate event

            //handle the timepicker changing
            //TODO:  why doesn't ko.utils.registerEventHandler work?
            $input.change(function () {
                var val = $input.val();
                settings.value(val);
            });

            //handle the observable changing
            settings.value.subscribe(function (newValue) {
                //set time picker value to the time string specified
                var value = newValue,
                current = $input.val()

                if (value != current) {
                    setValue(newValue);
                }
            });

            //set the initial value
            setValue(settings.value());

            function setValue(value) {
                var date = getDateFromTimeString(value);
                $input.timepicker('setTime', date);
            }

            function getDateFromTimeString(timeString) {
                if (timeString) {
                    //treat as time string at first
                    var date = new Date("01/01/1900 " + timeString);
                    if (timeString && (date.toString() === "Invalid Date")) {
                        //not a time string, so see if it's a date
                        date = new Date(timeString);
                        if ((date.toString() === "Invalid Date")) {
                            throw new Error("Time is not a valid format");
                        }
                    }
                    return date;
                } else {
                    return undefined;
                }
            }

            //handle disposal (if KO removes by the template binding)
            ko.utils.domNodeDisposal.addDisposeCallback($element[0], function () {
                $input.timepicker("destroy");
            });

        }

    })
});
