define('fusion/data',["fusion/log", "fusion/private/ajax", "fusion/private/data-service-cache", "durandal/system", "fusion/private/notice", "fusion/jquery", "fusion/utils", "fusion/config", "knockout", "require"],
    function (log, ajax, cache, system, notice, $, utils, $config, ko, require) {
        "use strict";

        var $data = {};

        var pendingPromises = {}; //object to track any pending request for specific key - so subsequent requests for same key can wait

        //default settings
        $data.settings = {
            /// <field name="cache" type="Boolean">Specifies whether the resulting data from the request should be stored in cache.  Default is false.  Must be false for POST requests</field>
            cache: false,
            /// <field name="async" type="Boolean">Specifies whether the request should return a value (async=false) or a promise (async=true).  Default is true</field>
            async: true,
            /// <field name="forceRefresh" type="Boolean">Specifies whether the request should requery the server, even if the value is already in cache.  Default is false</field>
            forceRefresh: false,
            mapping: false,      //specify true to automatically call ko.mapping.fromJS on the resulting object
            on401: function () { log.error("Not Authorized"); },
            apiServer: null, //This should be defaulted to `$config.baseUrl`, but it has to be done at time of actual call, because $log initialization is too early, and it may have changed since then.
            adalToken: null
        };

        //NOTE:  This doesn't work because $config.system.loginUrl is never set
        //var default401Handler = function () {
        //    if ($config.system && ($config.system.loginUrl !== void 0)) {
        //        //401 = unauthorized
        //        utils.cookie("returnurl", window.location.href);
        //        //perform redirect
        //        window.location.href = $config.system.loginUrl;
        //    }
        //}

        $data.clear = function (action, controller, parameters) {
            /// <signature>
            /// <summary>Clears the cache entry, if any, for the specified action, controller, and parameters</summary>
            /// <param name="action" type="String">The controller action where the request should be made</param>
            /// <param name="controller" type="String">The controller that should fulfill the request</param>
            /// <param name="parameters" type="Object (optional)">An object that contains parameters for the request in name:value format (e.g. {param1: "myParam"})</param>
            /// </signature>
            var key = generateCacheKey(action, controller, parameters);
            cache.remove(key, true);
        }


        $data.get = function (actionOrSettings, controller, parameters, settings) {
            /// <signature>
            /// <summary>Makes a GET request to specified controller action</summary>
            /// <param name="action" type="String">The controller action where the request should be made</param>
            /// <param name="controller" type="String">The controller that should fulfill the request</param>
            /// <param name="parameters" type="Object">An optional object that contains parameters for the request in name:value format (e.g. {param1: "myParam"})</param>
            /// <param name="settings" type="String">An object that contains additional settings for this data request</param>
            /// </signature>
            /// <signature>
            /// <summary>Makes a GET request to specified controller action using the specified settings</summary>
            /// <param name="settings" type="String">An object that contains settings for this data request</param>
            /// </signature>
            if (arguments.length === 1) {
                actionOrSettings.method = "GET";
                return getOrPost(actionOrSettings);
            }
            else {
                return getOrPost(actionOrSettings, controller, parameters, "GET", settings);
            }
        }

        $data.post = function (actionOrSettings, controller, parameters, settings) {
            /// <signature>
            /// <summary>Makes a POST request to specified controller action</summary>
            /// <param name="action" type="String">The controller action where the request should be made</param>
            /// <param name="controller" type="String">The controller that should fulfill the request</param>
            /// <param name="parameters" type="Object">An optional object that contains parameters for the request in name:value format (e.g. {param1: "myParam"})</param>
            /// <param name="settings" type="String">An optional object that contains additional settings for this data request</param>
            /// </signature>
            /// <signature>
            /// <summary>Makes a POST request to specified controller action using the specified settings</summary>
            /// <param name="settings" type="String">An object that contains settings for this data request</param>
            /// </signature>
            if (arguments.length === 1) {
                actionOrSettings.method = "POST";
                return getOrPost(actionOrSettings);
            }
            else {
                return getOrPost(actionOrSettings, controller, parameters, "POST", settings);
            }
        }

        $data.request = function (actionOrSettings, controller, parameters, method, settings) {
            /// <signature>
            /// <summary>Makes a request to specified controller action using any http method</summary>
            /// <param name="action" type="String">The controller action where the request should be made</param>
            /// <param name="controller" type="String">The controller that should fulfill the request</param>
            /// <param name="parameters" type="Object (optional)">An object that contains parameters for the request in name:value format (e.g. {param1: "myParam"})</param>
            /// <param name="method" type="String">The HTTP method to use to fulfill the request</param>
            /// <param name="settings" type="String">Additional settings for this data request</param>
            /// </signature>
            /// <signature>
            /// <summary>Makes a POST request to specified controller action using the specified settings</summary>
            /// <param name="settings" type="Object">An object that contains settings for the request.  Use data.settings() to obtain a new settings object.</param>
            /// </signature>
            if (arguments.length === 1) {
                return getOrPost(actionOrSettings);
            }
            else {
                return getOrPost(actionOrSettings, controller, parameters, method, settings);
            }
        }

        function getOrPost(action, controller, data, method, additionalSettings) {

            var settings = $.extend({}, $data.settings);
            if (arguments.length === 0) {
                throw new Error("no arguments specified");
            }
            else if (arguments.length === 1) {
                $.extend(settings, action);
            } else if (arguments.length === 5) {
                settings.action = action;
                settings.controller = controller;
                settings.parameters = data;
                settings.method = method;
                if (additionalSettings && (typeof additionalSettings === 'object')) {
                    $.extend(settings, additionalSettings);
                }
            } else {
                throw new Error("Invalid number arguments specified to getOrPost()");
            }



            // checking for malformed parameters
            if (settings.method === "POST" && settings.parameters && typeof (settings.parameters) !== "object") {
                throw new Error("Parameters must be an object on a POST request");
            }



            //cache should default to to false
            settings.cache = !!settings.cache;

            //provide backwards compatibility for settings.serverUrl (3/10/2016)
            if (settings.serverUrl) {
                //use console to prevent recursive logging calls
                window.console && window.console.warn && window.console.warn("`serverUrl` setting is deprecated in $data service.  Use `apiServer` instead.");
            }
            //use the applet's base url setting, if nothing else is specified
            settings.apiServer = settings.apiServer || settings.serverUrl || $config.baseUrl;


            if (settings.method === "POST") {

                // checking for the presence of parameters object -- catches case where on a POST all params are missing or null, 
                //  which means they don't get sent to the server
                if (settings.parameters) {
                    //add a dummy field so that POST requests work correctly, 
                    //  even when the other fields are null/undefined
                    settings.parameters._df = "";
                }


                //disallow caching for POSTs
                if (settings.cache) {
                    //warn users if resetting cache=false
                    log.warning("Cache is not allowed for POST requests.  Setting cache=false")
                    settings.cache = false;
                }
            }

            //if cached, return from cache (in-memory is the only cache supported)
            //else get from server and store in cache (if cache allowed)
            var key = generateCacheKey(settings.action, settings.controller, settings.parameters);


            return system.defer(function (dfd) {
                //if using cache, make sure there's not a request pending for the same key
                var waitForPending = (settings.cache ? pendingPromises[key] : null) || true;
                $.when(waitForPending).then(function () {
                    //if it's in cache, then use it, unless forceRefresh=true
                    if (!settings.forceRefresh && settings.cache && cache.hasKey(key) && !cache.expired(key)) {
                        dfd.resolve(cache.get(key), "", [], "", null);
                    }
                    else {
                        //query the server
                        var options = { loginUrl: settings.loginUrl };                            
                        if(settings.adalToken){
                            options.headers = { 'Authorization': 'Bearer ' + settings.adalToken };
                        }                        

                        pendingPromises[key] =
                        ajax.invokeAction(settings.action, settings.controller, settings.apiServer, settings.parameters, settings.method, options)
                            .then(success, fail)
                            .always(function () {
                                delete pendingPromises[key];
                            });
                    }

                    function getResponseType(statusCode) {
                        switch (statusCode.toString()) {
                            case "400":
                                return "validationError";
                            case "499":
                                return "webApiBusinessError";
                            default:
                                return "serverError";
                        }
                    }


                    function success(result, textStatus, jqXhr) {

                        var data = result.data;

                        //call default mapper
                        if (settings.mapping === true) {
                            data = ko.mapping.fromJS(data);
                        }
                        //TODO: support calling a mapping function

                        var cacheHeader = jqXhr.getResponseHeader("Fusion-Cache");
                        if (cacheHeader !== "no-cache") {
                            settings.cache && cache.set(key, data, settings.forceRefresh);
                        }

                        //TODO: idea
                        //try {
                        dfd.resolve(data, result.pageMessage, result.fieldMessages, "", jqXhr);
                        //} catch (e) {
                        //    $log.error(e);
                        //    throw e;
                        //}
                    }


                    function fail(jqXhr, textStatus, errorThrown) {
                        //this is the response that will be returned to the caller (as separate arguments)

                        //try to get the data if any is returned with the exception.
                        var data = {};
                        try {
                            data = JSON.parse(jqXhr.responseText);
                        } catch (e) {
                            //ignore
                        }

                        //set up a response object that has same shape as it would have if it were a successful call.
                        var response = {
                            data: data,
                            pageMessage: "",
                            fieldMessages: []
                        };

                        //note: for redirects, we abandon the promise entirely
                        switch (jqXhr.status) {

                            // ADDED ** v6.0.8
                            // This had to be changed to 5xx status code instead of 3xx
                            // because some browsers (safari) handle ALL 3xx status codes
                            // as redirects. This was causing safari to handle the redirect and
                            // return the new URL as http, which caused the ajax call to fail. 
                            // Switching this to 5xx code makes the browser see it as a 
                            // "server error" and lets it flow through to this handler.
                            case 555:
                            case 399:
                                //399 = custom fusion redirect - this is necessary instead of 301 because browsers 
                                //  internally handle std redirect  codes, and xhr only sees the end result code
                                var newUrl = jqXhr.getResponseHeader('Location');
                                //if specified, save return to url, in case new page needs to return
                                if (jqXhr.getResponseHeader('EnableReturnToUrl')) {
                                    utils.cookie("returnurl", window.location.href);
                                }
                                //perform redirect
                                //log.analytic("$data.399", newUrl);
                                log.analytic("System", "Http 399", "Redirecting to : " + newUrl);
                                window.location.href = newUrl;
                                return;
                            case 401:
                                //call on401 function.  If function returns true (indicating handled=true), then don't reject the data promise.
                                //log.analytic("$data.401", "");
                                log.analytic("System", "Http 401", "Unauthorzied");
                                if (settings.on401()) {
                                    return;
                                }
                        }

                        var isFusionResponse = false;
                        try {
                            var consoleMessage = "";
                            //try to parse the response manually
                            var obj = JSON.parse(jqXhr.responseText)

                            if (obj.hasOwnProperty("data") && obj.hasOwnProperty("pageMessage") && obj.hasOwnProperty("fieldMessages")) {
                                //this is standard fusion response
                                isFusionResponse = true;
                                response = obj;
                                consoleMessage = obj.pageMessage;
                            } else {
                                //deal with unknown response object.
                                response.pageMessage = obj.message || "We're sorry. An unknown error occurred. Please try again later."
                                consoleMessage = response.pageMessage + "\n " + jqXhr.status + " code returned.";
                            }
                        } catch (e) {
                            //deal with unknown/generic response object.
                            response.pageMessage = "We're sorry. An unknown error occurred. Please try again later."
                            consoleMessage = jqXhr.status + " code returned.";
                        }

                        //log to console, but don't send to server-side logger
                        //log.error(consoleMessage, jqXhr.responseText, true); //log error message and detail

                        //reject the deferred
                        dfd.reject(response.data, response.pageMessage, response.fieldMessages, getResponseType(jqXhr.status), jqXhr);
                    }

                });
            }).promise();
        }

        function generateCacheKey(action, controller, parameters) {
            return action + '|' + controller + '|' + (parameters ? JSON.stringify(parameters) : "");
        }

        //TODO: need to tap into system initialization chain so that we can pre-init any required data (e.g. userContext) during the app startup
        return $data;
    });

