define('fusion/ui/controls/fusion-toolbar',["fusion/fusion.control"], function (ControlFactory) {
    return ControlFactory.control(function (ko, $) {
        var c = this;

        c.settingsDefinition = {
            //buttons: { isLive: true, defaultValue: ko.observableArray([]) }
        };


        c.validateValues = function (settings) {
        }


        c.beforeBind = function ($markup, settings, bindingContext, $element) {

        }


        c.afterBind = function ($markup, settings, bindingContext, $element) {

        }

        c.afterDomInsert = function ($markup, settings, bindingContext) {

        };

    });

});
