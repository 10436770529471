define('fusion/log-collector',['require','fusion/event','fusion/data','fusion/log-flags'],function (require) {
    "use strict";
    var event = require("fusion/event");
    var data = require("fusion/data");
    var logFlags = require("fusion/log-flags");

    event.subscribe("log-collector:post-server-log", postServerLogMessage);
    event.subscribe("log-collector:post-server-analytic", postServerAnalyticMessage);


    //send log message to server
    function postServerLogMessage(params) {

        // if a trace entry -- send to Dynatrace rather than Log API
        if (params[0] === logFlags.trace) {

            if (typeof window.dT_ != 'undefined') {                 // ensuring DT is available
                var message = params[1];
                // call DT API to register the trace
                if (typeof message !== 'string') {
                    message = JSON.stringify(message);
                }
                dynaTrace.reportEvent(message);
            }
        }
        else {
            var parameters = {
                logType: params[0]
            };

            // if params is an obj, 
            if (typeof (params[1]) === "object") {
                parameters.message = params[1].message;
                parameters.detail= encodeURIComponent(assembleErrorDetail(params[1]));
                parameters.callingFunction= params[2];
            }
            else {
                parameters.message = params[1];
                if (params.length === 3) {
                    parameters.detail = params[2];
                }

                if (params.length === 4) {
                    parameters.detail = params[2];
                    parameters.callingFunction = params[3];
                }
            }


            var params = {
                action: "Log",
                controller: "Log",
                parameters: parameters,
                logErrors: false
            };

            data.post(params);
        }
    }



    function postServerAnalyticMessage(params) {
        var params = {
            action: "AnalyticLog",
            controller: "Log",
            parameters: {
                group: params[1],
                logKey: params[2],
                logValue: params[3],
                clientLogTimeStamp: "01/01/2001",
                component: "Browser"
            },
            logErrors: false
        };

        data.post(params);
    }



    
    // Serializes an error object for logger
    function assembleErrorDetail(error) {
        var errorDetail = 'ERROR: [' + error.toString() + ']\n' +
            '\nName:\t\t' + (error.name || '-') +
            '\nMessage:\t' + (error.message || '-') +
            '\nFile:\t\t\t' + (error.fileName || '-') +
            '\nSource:\t\t' + ((error.toSource && error.toSource()) || '-') +
            '\nLine #:\t\t' + (error.lineNumber || '-') +
            '\nColumn #:\t' + (error.columnNumber || '-') +
            '\n\nStack:\n\n' + (error.stack || '-');

        return errorDetail;
    }

});
