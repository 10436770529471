"use strict";
define('fusion/factory-helper',['require','fusion/data','fusion/jquery','fusion/applet','fusion/log','fusion/service','fusion/utils','fusion/event','fusion/navigation','fusion/private/dialog','fusion/validation','fusion/private/cache','fusion/config','fusion/message','fusion/service','knockout','ext/moment','ext/toastr/toastr-service','ext/jquery-signalR-2.2.0'],function (require) {

    var data = require("fusion/data");
    var $ = require("fusion/jquery");
    var applet = require("fusion/applet");
    var $log = require("fusion/log");
    var $service = require("fusion/service");

    //instantiate and return the specified service (starts with "$")
    //Note: This does not get parsed by requireJS, so this may actually fail
    //  if this code runs before the requested module has been loaded
    function getServiceOrExport(name, require, moduleBase) {
        switch (name) {
            case "$log":
                return getLogService(moduleBase);
            case "$data":
                return getDataService(moduleBase);
            case "$utility":
                return require("fusion/utils");
            case "$event":
                return require("fusion/event");
            case "$navigation":
                return require("fusion/navigation");
            case "$dialog":
                return require('fusion/private/dialog');
            case "$validate":
                return (require('fusion/validation')).validateViewModel;
            case "$cache":
                return require('fusion/private/cache');
            case "$config":
                return require("fusion/config");
            case "$message":
                return require("fusion/message");
            case "$service":
                return require("fusion/service");
            case "ko":
                return require("knockout");
            case "$":
                return $;
            case "require":
                return require;
            case "moment":
                return require("ext/moment");
            case "$toastr":
                return require("ext/toastr/toastr-service");
            case "$signalR":
                return require("ext/jquery-signalR-2.2.0");      // loads SigR scripts when VM requires the service

            default:
                //try to retrieve from $service collection.
                if ($service[name]) {
                    return $service[name];
                }

                throw new Error(name + " is not a supported service or export");
        }
    }

    function getLogService(module) {
        var moduleLogService = $.extend({}, $log);
        moduleLogService.moduleId = module.moduleId;
        return moduleLogService;
    }

    function getDataService(module) {
        var newData = $.extend({}, data);
        //re-wrap the get and post functions so that the applet's serverUrl property is applied
        newData.get = dataFunction("get");
        newData.post = dataFunction("post");

        function dataFunction(getOrPost) {
            return function () {
                var defer = $.Deferred();

                var args = Array.prototype.slice.call(arguments, 0)

                function success(data, pageMessage, fieldMessages, responseType, jqXhr) {
                    module.applyFieldMessages(fieldMessages);
                    module.setPageMessageHtml(pageMessage);
                    defer.resolve(data, pageMessage, fieldMessages, responseType, jqXhr);
                }

                function fail(data, pageMessage, fieldMessages, responseType, jqXhr) {
                    module.applyFieldMessages(fieldMessages);
                    module.setPageMessageHtml(pageMessage);
                    defer.reject(data, pageMessage, fieldMessages, responseType, jqXhr);
                }

                data[getOrPost].apply(module, args)
                .then(success, fail);

                return defer.promise();
            };
        }
        return newData;
    }

    return {
        //I pulled this logic from requireJS to parse
        //  out the `...=require()` calls so we can add
        //  them as dependencies before we call create the
        //  view model function.  That way, the view model code
        //  can use the var a = require("a") syntax as normal.
        parseDependencies: function (f) {
            var deps = [];
            var commentRegExp = /(\/\*([\s\S]*?)\*\/|([^:]|^)\/\/(.*)$)/mg,
                cjsRequireRegExp = /[^.]\s*require\s*\(\s*["']([^'"\s]+)["']\s*\)/g

            f.toString()
             .replace(commentRegExp, '')
             .replace(cjsRequireRegExp, function (match, dep) {
                 deps.push(dep);
             });

            return deps;
        },

        //parse and return an array of argument names
        //  from the view model function
        argumentNames: function (f) {
            var names = f.toString().match(/^[\s\(]*function[^(]*\(([^)]*)\)/)[1]
              .replace(/\/\/.*?[\r\n]|\/\*(?:.|[\r\n])*?\*\//g, '')
              .replace(/\s+/g, '').split(',');
            return names.length == 1 && !names[0] ? [] : names;
        },

        //returns an array of instantiated services/exports
        //  that are requested in the view model
        getServices: function (args, require, applet, moduleBase) {
            var services = [];

            args.forEach(function (argName) {
                services.push(getServiceOrExport(argName, require, applet, moduleBase));
            });

            return services;
        }
    };

});
