define('fusion/ui/controls/fusion-expander',["fusion/fusion.control"], function (ControlFactory) {
    return ControlFactory.control(function ($, ko, require) {
        var c = this;

        //reminder:  there is a single definition object (including the default values) that is used for multiple instances
        c.settingsDefinition = {
            mode: { isLive: false, defaultValue: 'accordion' },
            scrollIntoView: { isLive: true, defaultValue: false },
            expandedPanels: { isLive: true, liveType: ko.observableArray }    // array of panel indexes that indicate which panels are expanded
        };

        c.validateValues = function (settings) {
            settings("mode").throwIfNot().isOneOf(['accordion', 'expander']);
        };

        c.beforeBind = function ($markup, settings, bindingContext, $element) {
            if (!settings.expandedPanels()) {
                settings.expandedPanels = ko.observableArray();
            }
        };

        c.afterDomInsert = function ($markup, settings, bindingContext, $element) {

        };

    });
});
