define('fusion/ui/controls/fusion-checkboxgroup',["fusion/fusion.control"], function (ControlFactory) {
    return ControlFactory.control(function (ko) {
        var c = this;


        c.settingsDefinition = {
            headerText: { isLive: true, defaultValue: null },
            selectedValues: { isLive: true, isRequired: true, defaultValue: ko.observableArray() }
        };

        c.validateValues = function (settings) {
        }

        c.beforeBind = function ($markup, settings, bindingContext, $element) {
        }

        c.afterBind = function ($markup, settings, bindingContext, $element) {
        }

        c.afterDomInsert = function ($markup, settings, bindingContext) {
        }

    });
});
