define('fusion/ui/controls/fusion-radioexpander-panel',["fusion/fusion.control"], function (ControlFactory) {
    return ControlFactory.control(function ($, ko) {
        var m = this;

        var $root = $('html, body');

        m.settingsDefinition = {
            text: { isLive: true, isRequired: false },
            value: { isLive: true, isRequired: false, defaultValue: "" }
        };

        m.validateValues = function (settings) {
        }

        m.afterBind = function ($markup, settings, bindingContext, $element) {
        }

        m.afterDomInsert = function ($markup, settings, bindingContext) {

            var $expander = $markup.closest(".fusion-radioexpander");
            var $expanderPanel = $markup;

            //this will execute anytime the parent selected value changes, or if the value of this panel changes.
            ko.computed(function () {
                var scrollIntoView = bindingContext.$parentControl.scrollIntoView;      // note that setting of 'false' ( string val ) will be treated as true in this case

                if (bindingContext.$parentControl.selectedValue() === settings.value()) {
                    expanderOpen($expanderPanel, scrollIntoView());
                } else {
                    expanderClose($expanderPanel);
                }
            });
        };

        function expanderOpen($panel, scrollIntoView) {

            //don't try to expand if not visible.
            if (!$panel.closest(".fusion-radioexpander").is(":visible")) {
                return;
            }
            var ce = $panel.children('.content'),
                el = $panel.children('.content');

            el.slideDown(function () {
                if (scrollIntoView) {
                    $root.animate({
                        scrollTop: $(ce).offset().top - $(ce).outerHeight()
                    });
                }
            });

            el.parent('dd').addClass('active');
            el.addClass('active');
        }

        function expanderClose($panel) {

            //don't try to close if not visible.
            if (!$panel.closest(".fusion-radioexpander").is(":visible")) {
                return;
            }
            var el = $panel.children('.content');

            el.slideUp();
            el.parent('dd').removeClass('active');
            el.removeClass('active');
        }



    });

});
