"use strict";
define('fusion/ui/controls/fusion-chart',[
    "fusion/fusion.control",
    "ext/d3.min"
], function (ControlFactory, d3) {
    return ControlFactory.control(function ($utility, ko, require, $, $log) {
        var c = this;
        //var d3 = require("ext/d3.min");

        c.settingsDefinition = {
            options: { isRequired: true, isLive: false },
            charts: { isRequired: true, isLive: false },
            chartTitleHtml: { isRequired: false },
            chartFooterHtml: { isRequired: false }
        };



        //#region chart related enumerations and definitions
        var paddingBetweenRangeBands = 0.3;
        var outerPaddingRangeBands = 0.05;
        var tickRatio = 0.015;
        var chartTransitionDuration = 600;

        var marginDefinition = { top: 20, right: 20, bottom: 20, left: 20 };

        var padChar = "\u200B";                 // used to make X axis values unique -- non printable space

        var yAxisFormatOptions = {
            currency: "currency",
            temperature: "temperature",
            percentage: "percentage"
        };

        var chartTypes = {
            xy: "xy",
            line: "line"
            //stackedXY: "stackedXY"
        };

        var lineInterpolationOptions = ["linear", "step", "basis"];

        var labelRotationAngles = [0, 45, 90, -45, -90];



        var barChartYClickCorrection = -180;

        var defaultFillColor = "#008ac5";


        function logChartError(msg) {
            $log.warning(msg);
            //throw new Error(msg);
        }



        var xAxisLabelRotationAngle = null;

        var precision = 0;
        var commasFormatter = d3.format(",." + precision + "f");
        //var currencyFormatter = d3.format("$,0f");


        function setPrecisionElements(num) {
            commasFormatter = d3.format(",." + num + "f");
        }

        //#endregion chart related enumerations and definitions




        //#region validation helper functions

        // checking axis location parameter
        function validatateXAxisLocation(location) {
            if (ko.unwrap(location) !== "top" && ko.unwrap(location) !== "bottom") {
                logChartError("x axis location must either be defined as 'top' or 'bottom'.  Location is " + ko.unwrap(location));
            }
        }


        function validationXAxisLabelRotation(rotation) {

            xAxisLabelRotationAngle = parseInt(ko.unwrap(rotation));

            if (labelRotationAngles.indexOf(xAxisLabelRotationAngle) === -1) {
                logChartError("labelRotation does not match predefined label rotation angles :: " + xAxisLabelRotationAngle);
            }
        }


        function validatateYAxisLocation(location) {
            if (ko.unwrap(location) !== "left" && ko.unwrap(location) !== "right") {
                logChartError("y axis location must either be defined as 'left' or 'right'.  Location is " + ko.unwrap(location));
            }

        }


        function validateAxisId(id) {
            if (ko.unwrap(id) === null || ko.unwrap(id) === undefined) {
                logChartError("axis must have an ID specified.");
            }
        }

        //#endregion validation helper functions



        c.validateValues = function (settings) {
            var msg = "";

            // checking for direct content
            if (settings.__hasDirectContent) {
                logChartError("fusion-chart does not support direct content.");
            }


            //#region checking options parameters
            // since the options obj is compound, we must loop through the various obj properties and address each individually with custom checks
            //  NOTE :: the normal settings("type").throwIf()  type of syntax does not work for compound objects

            if (settings.options) {
                for (var p in settings.options) {
                    if (settings.options.hasOwnProperty(p)) {
                        switch (p) {
                            case "isBillingVariation":
                                if (typeof ko.unwrap(settings.options[p]) !== 'boolean') {
                                    logChartError("isBillingVariation property must be a boolean.");
                                }
                                break;



                            case "xAxis":
                                if (settings.options[p].length) {

                                    for (var x in settings.options[p]) {
                                        if (settings.options[p].hasOwnProperty(x)) {

                                            // checking x axis location
                                            validatateXAxisLocation(settings.options[p][x].location);

                                            // checking x axis label rotration
                                            if (settings.options[p][x].labelRotation !== null) {
                                                validationXAxisLabelRotation(settings.options[p][x].labelRotation);
                                            }

                                            // checking for x axis ID
                                            validateAxisId(settings.options[p][x].id);
                                        }
                                    }

                                }
                                else {
                                    validatateXAxisLocation(settings.options[p].location);

                                    if (settings.options[p].labelRotation !== null) {
                                        validationXAxisLabelRotation(settings.options[p].labelRotation);
                                    }

                                    // checking for x axis ID
                                    validateAxisId(settings.options[p].id);

                                }


                                break;



                            case "yAxis":
                                if (settings.options[p].length) {
                                    for (var x in settings.options[p]) {
                                        if (settings.options[p].hasOwnProperty(x)) {

                                            // checking Y axis location
                                            validatateYAxisLocation(settings.options[p][x].location);


                                            if (ko.unwrap(parseInt(settings.options[p][x].decimalPrecision))) {
                                                precision = ko.unwrap(parseInt(settings.options[p][x].decimalPrecision));
                                                setPrecisionElements(precision);
                                            }

                                            // checking for y axis ID
                                            validateAxisId(settings.options[p][x].id);

                                        }
                                    }
                                }
                                else {

                                    // checking Y axis location
                                    validatateYAxisLocation(settings.options[p].location);

                                    if (ko.unwrap(parseInt(settings.options[p].decimalPrecision))) {
                                        precision = ko.unwrap(parseInt(settings.options[p].decimalPrecision));
                                        setPrecisionElements(precision);
                                    }


                                    // checking for x axis ID
                                    validateAxisId(settings.options[p].id);

                                }

                                break;



                            default:
                                break;
                        }
                    }
                }
            }
            //#endregion checking options parameters


            //#region checking chart parameters
            if (settings.charts) {

                if (Array.isArray(settings.charts)) {

                    ko.utils.arrayForEach(settings.charts, function (_chartObj, chart) {

                        if (!settings.charts[chart].xAxisId) {
                            logChartError("Chart must have an xAxisId specified");
                        }


                        for (var p in settings.charts[chart]) {
                            if (settings.charts[chart].hasOwnProperty(p)) {

                                switch (p) {

                                    case "type":
                                        var found = false;
                                        for (var charttype in chartTypes) {
                                            if (charttype === ko.unwrap(settings.charts[chart].type)) {
                                                found = true;
                                            }
                                        }
                                        if (!found) {
                                            logChartError("Unknown chart type - " + settings.charts[chart].type);
                                        }
                                        break;

                                        //case "isToolTipEnabled":
                                        //    if (typeof settings.charts[chart].isToolTipEnabled !== 'boolean') {
                                        //        msg = "It's truthy/falsy.  Check it out and try again";
                                        //        $log.error(msg);
                                        //        throw new Error(msg);
                                        //    }
                                        //    break;

                                    case "series":
                                        // $log.trace("checking chart series");
                                        if (settings.charts[chart].series) {
                                            if (!Array.isArray(ko.unwrap(settings.charts[chart].series))) {
                                                logChartError("Chart data series must be an array");
                                            }
                                        }
                                        else {
                                            logChartError("Chart object must have chart data defined");
                                        }
                                        break;


                                    case "lineInterpolation":
                                        if (settings.charts[chart].lineInterpolation) {
                                            if (lineInterpolationOptions.indexOf(ko.unwrap(settings.charts[chart].lineInterpolation)) === -1) {
                                                logChartError("lineInterpolation does not match predefined lineInterpolation options :: " + ko.unwrap(settings.charts[chart].lineInterpolation));
                                            }
                                        }
                                        break;

                                    default:
                                        break;
                                }
                            }
                        }
                    });
                }
                else {
                    logChartError("Charts object must be an array of");
                }
            }
            else {
                logChartError("No charts are specified.");
            }

            //#endregion checking chart parameters


        }








        c.beforeBind = function ($markup, settings, bindingContext, $element) {

        }



        c.afterBind = function ($markup, settings, bindingContext, $element) {

        }



        c.afterDomInsert = function ($markup, settings, bindingContext) {

            // get reference to the chart container
            var $chartContainer = $markup.children(".chart-container").children(".chart");




            // if first chart series is observable, check for data to see if we need to subscribe in case of late arriving data
            if (ko.isObservable(settings.charts[0].series)) {

                // if there is something in the array, go ahead and create chart
                if (ko.unwrap(settings.charts[0].series()).length > 0) {
                    createChart($chartContainer, settings);
                }

                    // otherwise subscribe to the series in case of a change
                else {
                    var subscription = settings.charts[0].series.subscribe(function (newValue) {

                        // note that subscription is passed to createChart so that it can be disposed of once this fires
                        createChart($chartContainer, settings, subscription);
                    });
                }
            }
                // otherwise, call for chart creation
            else {
                createChart($chartContainer, settings);
            }






            //#region chart utility functions
            // utility method that will recursively pad a str with an amount of padding chars
            function pad(str, padAmt) {
                return (str.toString().length < (padAmt + str.toString().length)) ? pad((str + padChar).toString(), padAmt - 1) : str.toString();
            }



            function padDataArray(data) {
                var testArray = (ko.isObservable(data)) ? ko.observableArray() : [];

                var indexor = 1;

                for (var i = 0; i < ko.unwrap(data).length; i++) {

                    // a duplicate entry is found, append a space to the end of the X val to make it unique
                    if (testArray.indexOf(ko.unwrap(data)[i].x) !== -1) {
                        if (ko.isObservable(data)) {
                            data()[i].x = pad(ko.unwrap(data)[i].x, indexor);
                        }
                        else {
                            data[i].x = pad(data[i].x, indexor);
                        }

                        indexor++;
                    }

                    testArray.push(ko.unwrap(data)[i].x);
                }

                return data;
            }


            function getTickSize() {
                var tickRatio = 0.015;
                return Math.round(tickRatio * settings.chartobj.width);
            }



            function addZeroLineMarker(currentChart) {

                var axisObj = settings.chartobj.yAxisObjs[currentChart.yAxisId];

                // if this is an update, remove the zero line associated with the current axis
                if (settings.chartobj.isUpdate) {
                    d3.selectAll("." + "zero-marker-" + axisObj.id).remove();
                }

                //var xmin = settings.chartobj.xScales[currentChart.xAxisId](d3.min(axisObj.unwrappedYScales, function (d) { return d.x }));
                var xmin = settings.chartobj.xScales[currentChart.xAxisId](0);

                //var xmax = settings.chartobj.xScales[currentChart.xAxisId](d3.max(axisObj.unwrappedYScales, function (d) { return d.x }));
                //var xmax = settings.chartobj.xScales[currentChart.xAxisId](d3.max(axisObj.unwrappedYScales.domain()));
                var xmax = settings.chartobj.xScales[currentChart.xAxisId](settings.chartobj.xScales[currentChart.xAxisId].domain()[settings.chartobj.xScales[currentChart.xAxisId].domain().length - 1]);

                //d3.max(settings.chartobj.yScales[property].domain())

                var ymin = settings.chartobj.yScales[axisObj.id](0);
                var ymax = settings.chartobj.yScales[axisObj.id](0);

                // add to xmax if there is a rangeband to consider
                if (settings.chartobj.xScales[currentChart.xAxisId].rangeBand()) {
                    xmax = xmax + settings.chartobj.xScales[currentChart.xAxisId].rangeBand();
                }

                settings.chartobj.chartSVG.append("line")
                    .attr("class", "zero-marker-" + axisObj.id)
                    .attr("x1", xmin)
                    .attr("x2", xmax)
                    .attr("y1", ymin)
                    .attr("y2", ymax)
                    .attr("stroke-width", 2)
                    .attr("stroke", "#555")
                    .attr('stroke-dasharray', "5, 5");
            }

            //#endregion chart utility functions



            //#region chart creation functions

            //#region individual chart type creation functions
            function createBarChart(currentChart) {
                // article on how to put tooltips on the bars :: http://bl.ocks.org/Caged/6476579  -- in case it is needed in future

                //debugger;

                var barXScale = settings.chartobj.xScales[currentChart.xAxisId];
                var bar;
                if (settings.chartobj.isUpdate) {
                    $log.trace("Updating bar chart id = " + currentChart.id);

                    bar = settings.chartobj.chartSVG.selectAll("#" + currentChart.id).selectAll(".bar")
                    .data(ko.unwrap(currentChart.series));

                }
                else {
                    $log.trace("Creating bar chart id = " + currentChart.id);

                    // create SVG g element to hold the bar chart
                    var barchart = settings.chartobj.chartSVG.append("g")
                    .attr("class", "barchart")
                    .attr("id", currentChart.id);


                    // bar creation "function" -- creates the bar via the enter command, which processes the series data and adds a rect element to represent each bar
                    bar = barchart.selectAll(".bar")
                        .data(ko.unwrap(currentChart.series));

                }


                // remove old data
                bar.exit().remove();



                // new data
                bar.enter().append("rect")
                   .attr("transform", function (d) {
                       return "translate(" + barXScale(d.x) + ", " + paddingBetweenRangeBands + ")";
                   })
                    .attr("class", function (d) {
                        // append additional class names if one is specified for a bar data entry
                        return (d.cssClass !== null && d.cssClass !== undefined) ? "bar " + d.cssClass : "bar";
                    })
                    .attr("width", barXScale.rangeBand())
                    .attr('fill', function (d) {
                        return (d.fillColor) ? d.fillColor : defaultFillColor;

                    })
                    .on('click', function (d) {                                 // click causes tooltip to show
                        //.on('mouseover', function (d) {

                        if (currentChart.isToolTipEnabled) {
                            settings.chartobj.toolTipDiv.transition()
                               .duration(200)
                               .style("opacity", .9);

                            settings.chartobj.toolTipDiv.html(d.y)
                               .style("left", (d3.event.pageX) + "px")
                               .style("top", (d3.event.pageY - 28) + "px");
                        }
                    })
                    .on("mouseout", function (d) {                              // moving mouse off of bar causes tooltip to close
                        if (currentChart.isToolTipEnabled) {
                            settings.chartobj.toolTipDiv.transition()
                                .duration(chartTransitionDuration)
                                .style("opacity", 0);
                        }
                    })
                    .attr("y", function (d) {
                        if (settings.chartobj.yAxisObjs[currentChart.yAxisId].ymin < 0) {
                            return settings.chartobj.yScales[currentChart.yAxisId](Math.max(0, d.y));
                        }
                        else {
                            return settings.chartobj.yScales[currentChart.yAxisId](d.y);
                        }
                    })
                    .attr("height", function (d) {
                        if (settings.chartobj.yAxisObjs[currentChart.yAxisId].ymin < 0) {
                            return Math.abs(settings.chartobj.yScales[currentChart.yAxisId](d.y) - settings.chartobj.yScales[currentChart.yAxisId](0));
                        }
                        else {
                            return settings.chartobj.height - settings.chartobj.yScales[currentChart.yAxisId](d.y);
                        }
                    });





                // updated data
                bar.attr("transform", function (d) {
                    return "translate(" + barXScale(d.x) + ", " + paddingBetweenRangeBands + ")";
                })
                    .attr("class", function (d) {
                        // append additional class names if one is specified for a bar data entry
                        return (d.cssClass !== null && d.cssClass !== undefined) ? "bar " + d.cssClass : "bar";
                    })
                    .attr("width", barXScale.rangeBand())
                    .attr('fill', function (d) {

                        return (d.fillColor) ? d.fillColor : defaultFillColor;

                    })
                    .on('click', function (d) {                                 // click causes tooltip to show

                        if (currentChart.isToolTipEnabled) {
                            settings.chartobj.toolTipDiv.transition()
                               .duration(200)
                               .style("opacity", .9);

                            settings.chartobj.toolTipDiv.html(d.y)
                               .style("left", (d3.event.pageX) + "px")
                               .style("top", (d3.event.pageY + barChartYClickCorrection) + "px");
                        }
                    })
                    .on("mouseout", function (d) {                              // moving mouse off of bar causes tooltip to close
                        if (currentChart.isToolTipEnabled) {
                            settings.chartobj.toolTipDiv.transition()
                                .duration(chartTransitionDuration)
                                .style("opacity", 0);
                        }
                    })
                    .attr("y", function (d) {
                        if (settings.chartobj.yAxisObjs[currentChart.yAxisId].ymin < 0) {
                            return settings.chartobj.yScales[currentChart.yAxisId](Math.max(0, d.y));
                        }
                        else {
                            return settings.chartobj.yScales[currentChart.yAxisId](d.y);
                        }
                    })
                    .attr("height", function (d) {
                        if (settings.chartobj.yAxisObjs[currentChart.yAxisId].ymin < 0) {
                            return Math.abs(settings.chartobj.yScales[currentChart.yAxisId](d.y) - settings.chartobj.yScales[currentChart.yAxisId](0));
                        }
                        else {
                            return settings.chartobj.height - settings.chartobj.yScales[currentChart.yAxisId](d.y);
                        }
                    });



                //    on('click', function (d) {
                //    //.on('mouseover', function (d) {
                //    if (currentChart.isToolTipEnabled) {
                //        settings.chartobj.toolTipDiv.transition()
                //           .duration(200)
                //           .style("opacity", .9);
                //        settings.chartobj.toolTipDiv.html(d.y)
                //           .style("left", (d3.event.pageX) + "px")
                //           .style("top", (d3.event.pageY - 28) + "px");
                //    }
                //})
                //    .on("mouseout", function (d) {
                //        if (currentChart.isToolTipEnabled) {
                //            settings.chartobj.toolTipDiv.transition()
                //                .duration(chartTransitionDuration)
                //                .style("opacity", 0);
                //        }
                //    }).transition()
                //    .duration(chartTransitionDuration)
                //    .attr("transform", function (d) { return "translate(" + barXScale(d.x) + ", " + paddingBetweenRangeBands + ")"; })
                //    .attr('fill', function (d) {
                //        var patternUrl = null;

                //        // first check to see if a fill pattern is specified for the current bar
                //        if (d.fillPattern) {

                //            // if a fill pattern is specified, make sure that it is a legitimate pattern
                //            if (fillPatterns.indexOf(d.fillPattern) > -1) {
                //                patternUrl = "url(#" + d.fillPattern + ")";
                //            }
                //            else {
                //                logChartError(d.fillPattern + " is not a legitimate fill pattern.");
                //            }
                //        }

                //        return patternUrl;
                //    })

                //    .attr("width", barXScale.rangeBand())

                //    .attr("y", function (d) {
                //        if (settings.chartobj.yAxisObjs[currentChart.yAxisId].ymin < 0) {
                //            return settings.chartobj.yScales[currentChart.yAxisId](Math.max(0, d.y));
                //        }
                //        else {
                //            return settings.chartobj.yScales[currentChart.yAxisId](d.y);
                //        }
                //    })
                //    .attr("height", function (d) {
                //        if (settings.chartobj.yAxisObjs[currentChart.yAxisId].ymin < 0) {
                //            return Math.abs(settings.chartobj.yScales[currentChart.yAxisId](d.y) - settings.chartobj.yScales[currentChart.yAxisId](0));
                //        }
                //        else {
                //            return settings.chartobj.height - settings.chartobj.yScales[currentChart.yAxisId](d.y);
                //        }
                //    });



            }


            function createLineChart(currentChart) {
                // Good article :: https://www.dashingd3js.com/svg-paths-and-d3js 

                var barXScale = settings.chartobj.xScales[currentChart.xAxisId];
                var barYScale = settings.chartobj.yScales[currentChart.yAxisId];

                // doubling the series data only if it is a billing variety line chart
                if (settings.chartobj.isBillingVariation) {
                    var newseries = [];
                    for (var i = 0; i < ko.unwrap(currentChart.series).length; i++) {
                        // pushing on rightmost x point coordinate
                        newseries.push({
                            x: ko.unwrap(currentChart.series)[i].x,
                            y: ko.unwrap(currentChart.series)[i].y
                        });

                        // pushing on rightmost x point coordinate
                        newseries.push({
                            x: ko.unwrap(currentChart.series)[i].x,
                            y: ko.unwrap(currentChart.series)[i].y
                        });
                    }

                }


                // defining normal line generator function
                var lineGen = d3.svg.line()
                .x(function (d) {
                    return barXScale(d.x) + barXScale.rangeBand() / 2;
                    //return barXScale(d.x);
                })
                .y(function (d) {
                    return barYScale(d.y);
                })
                .interpolate(currentChart.lineInterpolation);



                // defining the line generation function specific to Billing
                var lineGenBilling = d3.svg.line()
                .x(function (d, iterator) {                                   // addressing the x coordinate, applying the xScale to transform the line across the plotting space
                    var offset = barXScale.rangeBand();
                    // $log.trace("lineGenBilling Offset = " + offset);
                    if (iterator % 2 === 0) {
                        //$log.trace("even : x = " + d.x + " :: chartObj.xScale(d.x) = " + chartObj.xScale(d.x));

                        // experimenting with a way to shift the first data point to the left axis, but this happends for all line charts so will need to be more specific in the check
                        if (iterator === 0) {                           // leftmost X value possible -- shift line to start of Y axis
                            return 0;
                        }
                        else if (iterator === 23) {
                            var calcX = (barXScale(d.x) + (2 * offset));
                            return calcX;
                        }
                        else {
                            return barXScale(d.x);
                        }

                        return barXScale(d.x);
                    }
                    else {
                        //$log.trace("odd : x = " + d.x + " :: chartObj.xScale(d.x) = " + chartObj.xScale(d.x));
                        return barXScale(d.x) + offset;
                    }
                })
                .y(function (d, iterator) {                              // addressing the y coordinate, applying the yScale to transform the line across the plotting space
                    return barYScale(d.y);
                })
                .interpolate(currentChart.lineInterpolation);




                // Defining which data series and line generator to use based on whether chart is a billing variety
                var iterator = 0;
                var lineGenFunctionToUse = (settings.chartobj.isBillingVariation) ? lineGenBilling : lineGen;
                var dataSeries = (settings.chartobj.isBillingVariation) ? newseries : currentChart.series;



                // creating CSS class name to use when appending line to the SVG element
                var cssClassName = (currentChart.lineClass !== undefined) ? 'line ' + currentChart.lineClass : 'line';




                if (settings.chartobj.isUpdate) {
                    settings.chartobj.chartSVG.select("path#" + currentChart.id)
                        .transition()
                        .duration(chartTransitionDuration)
                        .attr("d", lineGenFunctionToUse(ko.unwrap(dataSeries), iterator))


                    $log.trace("Updating line chart id = " + currentChart.id);
                }
                else {
                    // create SVG g element to hold the line chart
                    var linechart = settings.chartobj.chartSVG.append("g")
                    .attr("class", "linechart");

                    // appending the line to the chart SVG -- note calling the line generator on the series passed in
                    linechart.append('svg:path')
                        .attr('d', lineGenFunctionToUse(ko.unwrap(dataSeries), iterator))
                        .attr('class', cssClassName)
                        .attr("id", currentChart.id);

                }
            }


            function createStackedChart(series) {

                //var nestFunction = d3.nest().key(function (d) { return d.x });
                //var nestedData = nestFunction.entries(series);

                //nestedData.forEach(function (group) {
                //    //series.forEach(function (group) {

                //    //group.x = group.key;
                //    group.bars = [];

                //    var total = 0;
                //    //group.values[0].y.forEach(function(row){
                //    group.values.forEach(function (row) {

                //        row.y.forEach(function (rowItem) {

                //            group.bars.push(
                //                {
                //                    x: row.x,
                //                    //count: +row.y,
                //                    count: row.y.length,
                //                    y0: total,
                //                    //y1: (total = total + +row.y)
                //                    y1: (total = total + row.y)
                //                });
                //        });
                //    });
                //});


                //var xGroups = chartObj.chartSVG.selectAll("g")
                //.data(nestedData)
                ////.data(series)
                //.enter().append("g")
                //.attr("class", "xGroup");

                var xGroups = settings.chartobj.chartSVG.selectAll("g")
                .data(series)
                .enter().append("g")
                .attr("class", "xGroup");

                var bars = xGroups.selectAll("rect")
                .data(function (d) { return d.bars; });

                bars.enter().append("rect");

                bars.attr("class", function (/*d*/) {
                    return "classFred";
                })
                .attr("y", function (d) {
                    return settings.chartobj.yScale(d.y1);
                })
                .attr("height", function (d) {
                    return Math.abs(settings.chartobj.yScale(d.y1) - settings.chartobj.yScale(d.y0));
                })
                .attr("x", settings.chartobj.xScale.rangeBand());

                //.enter().append("g")
                //.attr("transform", function (d) { return "translate(" + chartObj.xScale(d.x) + ",0.3"; });

                //bars.selectAll("rect")
                //.data(function(d) {return d.y}
            }

            //#endregion individual chart type creation functions





            //#region scale related functions
            function processScales() {
                // process X scales
                if (settings.options.xAxis.length) {
                    for (var i = 0; i < settings.options.xAxis.length; i++) {
                        settings.chartobj.xScales[settings.options.xAxis[i].id] = setXScale(settings.chartobj.width, settings.options.xAxis[i]);
                    }
                }
                else {
                    settings.chartobj.xScales[settings.options.xAxis.id] = setXScale(settings.chartobj.width, settings.options.xAxis);
                }

                // process Y scales
                if (settings.options.yAxis.length) {
                    for (var i = 0; i < settings.options.yAxis.length; i++) {
                        settings.chartobj.yScales[settings.options.yAxis[i].id] = setYScale(settings.chartobj.height, settings.options.yAxis[i]);
                    }
                }
                else {
                    settings.chartobj.yScales[settings.options.yAxis.id] = setYScale(settings.chartobj.height, settings.options.yAxis);
                }
            }


            function setXScale(width, axisObj) {

                $log.trace("setXScale for " + axisObj.id + " - width - " + width);

                //var allowDuplicates = true;
                //var currentValIsDuplicate = true;
                var iterator = 0;

                settings.chartobj.xAxisObjs[axisObj.id].unwrappedXScales = [].concat.apply([], axisObj.xScaleData);

                //NOTE :: an issue with ordinal scale is that duplicate values are not shown explicitly -- prob will have to use linear scaling
                //var x = d3.scale.ordinal()
                //.rangeRoundBands([0, width], paddingBetweenRangeBands, outerPaddingRangeBands)
                //.domain(ko.unwrap(axisObj.xScaleData).map(function (d, iterator) {
                //    return d.x;
                //}));



                var x = d3.scale.ordinal()
                .rangeRoundBands([0, width], paddingBetweenRangeBands, outerPaddingRangeBands)
                .domain(
                    settings.chartobj.xAxisObjs[axisObj.id].unwrappedXScales.map(function (d) { return d.x })
                 );


                //var x = d3.scale.linear()
                //    .domain([0, axisObj.data.length])
                //    .range([0, width]);

                return x;
            }


            function setYScale(height, axisObj) {

                settings.chartobj.yAxisObjs[axisObj.id].unwrappedYScales = [].concat.apply([], axisObj.yScaleData);

                //#region find Y min/max for the axisObj and store it
                axisObj.ymin = d3.min(settings.chartobj.yAxisObjs[axisObj.id].unwrappedYScales, function (d) { return parseFloat(d.y); });
                axisObj.ymax = d3.max(settings.chartobj.yAxisObjs[axisObj.id].unwrappedYScales, function (d) { return parseFloat(d.y); });

                //#endregion find Y min/max for the axisObj and store it

                var y;
                if (axisObj.ymin < 0 && axisObj.ymax > 0) {
                    y = d3.scale.linear()
                        .domain(d3.extent(settings.chartobj.yAxisObjs[axisObj.id].unwrappedYScales, function (d) {
                            return d.y;
                        }))
                        .range([height, 0]);
                }
                else if (axisObj.ymax <= 0 && axisObj.ymin < 0) {
                    y = d3.scale.linear()
                        .range([0, height])
                        .domain([0, axisObj.ymin]);
                }

                else {
                    y = d3.scale.linear()
                        .range([height, 0])
                        .domain([0, axisObj.ymax]);
                }

                return y;
            }
            //#endregion scale related functions




            //#region axis related
            function createXAxesElements() {

                // need to ensure on update we reset the x axis label rotation
                if (settings.chartobj.isUpdate) {

                    // clear existing xaxis if this is an update
                    settings.chartobj.chartSVG.selectAll(".xaxis").remove();
                }


                for (var property in settings.chartobj.xAxisObjs) {
                    if (settings.chartobj.xAxisObjs.hasOwnProperty(property)) {

                        xAxisLabelRotationAngle = parseInt(ko.unwrap(settings.chartobj.xAxisObjs[property].labelRotation));


                        var billingVariationTickSize = Math.round(tickRatio * settings.chartobj.width);
                        billingVariationTickSize = (settings.chartobj.isBillingVariation === true) ? -billingVariationTickSize : billingVariationTickSize;

                        // quick reference to current x scale element
                        var xscale = settings.chartobj.xScales[property];

                        // xAxis creation function
                        var xAxis = d3.svg.axis()
                            .scale(xscale)
                            .orient(settings.chartobj.xAxisObjs[property].location)
                            .outerTickSize(0)
                            .innerTickSize(billingVariationTickSize);




                        // filtering xAxis if a filter function is associated with the current axis
                        if (settings.chartobj.xAxisObjs[property].tickFilterFunction !== null && settings.chartobj.xAxisObjs[property].tickFilterFunction !== undefined) {
                            try {
                                xAxis.tickValues(xscale.domain().filter(settings.chartobj.xAxisObjs[property].tickFilterFunction));
                            }
                            catch (e) {
                                var msg = "Failed to filter xAxis ticks for " + settings.chartobj.xAxisObjs[property] + ".  " + e.message;
                                logChartError(msg);
                            }
                        }





                        // #region determining the label text anchor location
                        var textAnchorBasedOnRotationAngle = "middle";
                        switch (xAxisLabelRotationAngle) {
                            case 45:
                            case 90:
                                textAnchorBasedOnRotationAngle = (settings.chartobj.xAxisObjs[property].location === "bottom") ? "start" : "end";
                                break;

                            case -45:
                            case -90:
                                textAnchorBasedOnRotationAngle = (settings.chartobj.xAxisObjs[property].location === "bottom") ? "end" : "start";
                                break;

                            default:
                                textAnchorBasedOnRotationAngle = "middle";
                                break;
                        }
                        // #endregion determining the label text anchor location



                        // rotating x axis labels
                        settings.chartobj.chartSVG.append("g")
                           .attr("class", "xaxis " + property)
                           .attr("transform", function () {

                               if (settings.chartobj.xAxisObjs[property].location === "bottom") {
                                   return "translate(0," + settings.chartobj.height + ")"
                               }
                               else if (settings.chartobj.xAxisObjs[property].location === "top") {
                                   return "translate(0,0)";
                               }
                               else {
                                   logChartError("fusion-chart error : invalid xAxis object location :: " + settings.chartobj.xAxisObjs[property].location);
                               }
                           })
                           .call(xAxis)
                                .selectAll("text")
                                .style("text-anchor", textAnchorBasedOnRotationAngle)
                                .attr("transform", function (d) {

                                    var xShift = 0;
                                    var yShift = (settings.chartobj.xAxisObjs[property].location === "bottom") ? 10 : -10;

                                    var paddingAdjustment = (settings.chartobj.xScales[property].rangeBand() * paddingBetweenRangeBands);
                                    var outerPaddingAdjustment = 3 * (settings.chartobj.xScales[property].rangeBand() * outerPaddingRangeBands);

                                    if (xAxisLabelRotationAngle) {

                                        if (xAxisLabelRotationAngle === 45 || xAxisLabelRotationAngle === -45) {
                                            if (settings.chartobj.xAxisObjs[property].location === "bottom") {
                                                xShift = (xAxisLabelRotationAngle < 0) ? -(settings.chartobj.xScales[property].rangeBand() / 4 - paddingAdjustment) - (outerPaddingAdjustment * 2) : settings.chartobj.xScales[property].rangeBand() / 4 - paddingAdjustment + outerPaddingAdjustment;
                                            }
                                            else {
                                                xShift = (xAxisLabelRotationAngle < 0) ? (settings.chartobj.xScales[property].rangeBand() / 4 + paddingAdjustment) - (outerPaddingAdjustment * 2) : -settings.chartobj.xScales[property].rangeBand() / 4 - paddingAdjustment + (outerPaddingAdjustment * 2);
                                                //xShift = -10;
                                            }
                                            yShift = (settings.chartobj.xAxisObjs[property].location === "bottom") ? 5 : -5;
                                        }
                                        else if (xAxisLabelRotationAngle === 90 || xAxisLabelRotationAngle === -90) {
                                            if (settings.chartobj.xAxisObjs[property].location === "bottom") {
                                                xShift = (xAxisLabelRotationAngle < 0) ? -(settings.chartobj.xScales[property].rangeBand() / 2 - paddingAdjustment) - (outerPaddingAdjustment) : settings.chartobj.xScales[property].rangeBand() / 2 - paddingAdjustment + outerPaddingAdjustment;
                                            }
                                            else {
                                                xShift = (xAxisLabelRotationAngle < 0) ? (paddingAdjustment + outerPaddingAdjustment) : -(paddingAdjustment + outerPaddingAdjustment);
                                                yShift = -20;
                                            }
                                        }
                                        else {
                                            xShift = 0;
                                        }

                                        //translating labels to correct position and then rotating them
                                        return 'translate(' + xShift + ', ' + yShift + '), rotate(' + xAxisLabelRotationAngle + ')';

                                    }
                                    else {
                                        // $log.trace("x axis rotation: Default transform/translation");
                                        return 'translate(0, ' + yShift + ')';
                                    }
                                });


                        //#region adjustments on xaxis for billing chart variations
                        if (settings.chartobj.isBillingVariation) {

                            //calculating the distance to shift the x axis ticks based on range band and padding between elements
                            var midBandDistance = Math.round((settings.chartobj.xScales[property].rangeBand() / 2) + (settings.chartobj.xScales[property].rangeBand() * (paddingBetweenRangeBands / 1.5)));

                            // shifting tick marks to midpoint between rangebands
                            var ticks = d3.select(settings.chartobj.chartSVG)[0][0].select("g.xaxis").selectAll("line")
                            .attr("x1", Math.round(midBandDistance))
                            .attr("x2", Math.round(midBandDistance));
                        }
                        //#endregion adjustments on xaxis for billing chart variations





                        //#region increasing chart container height if labels are rotated

                        //NOTE :: http://tutorials.jenkov.com/svg/svg-viewport-view-box.html  -- "If you do not specify any units inside the width and height attributes, the units are assumed to be pixels. That is, a width 500 means 500 pixels."
                        if (!settings.chartobj.isUpdate) {
                            var viewBoxDimensions = settings.chartobj.$chartContainerId[0].getAttribute("viewBox").split(" ");
                            var chartContainerHeightAdjustment = settings.chartobj.chartSVG.selectAll("." + property)[0][0].getBBox().height;
                            settings.chartobj.$chartContainerId[0].setAttribute("viewBox", viewBoxDimensions[0] + " " + viewBoxDimensions[1] + " " + viewBoxDimensions[2] + " " + Math.round(parseInt(viewBoxDimensions[3]) + chartContainerHeightAdjustment));

                            // adding top margin if axis is placed at the top
                            if (settings.chartobj.xAxisObjs[property].location === "top") {
                                $(settings.chartobj.$chartContainerId).parent().css("padding-top", (parseInt($(settings.chartobj.$chartContainerId).parent().css("padding")) + chartContainerHeightAdjustment) + "px");
                            }
                        }
                        //#endregion increasing chart container height if labels are rotated


                    }
                }

            }



            function createYAxesElements() {

                // need to ensure on update we reset the x axis label rotation
                if (settings.chartobj.isUpdate) {

                    // clear existing xaxis if this is an update
                    settings.chartobj.chartSVG.selectAll(".yaxis").remove();
                }


                for (var property in settings.chartobj.yAxisObjs) {

                    if (settings.chartobj.yAxisObjs.hasOwnProperty(property)) {

                        //#region creating Y axis and appending it to the chart SVG element

                        /* NOTE about axis ticks : http://stackoverflow.com/questions/13100314/change-the-ticks-on-x-axis
                            * The default ticks for quantitative scales are multiples of 2, 5 and 10. You appear to want multiples of 6; though unusual, this could make sense depending on 
                            * the nature of the underlying data. So, while you can use axis.ticks to increase or decrease the tick count, it will always return multiples of 2, 5 and 10.
                        */


                        // yAxis creation function
                        var yAxis = d3.svg.axis()
                            .scale(settings.chartobj.yScales[property])
                            .orient(settings.chartobj.yAxisObjs[property].location)
                            .outerTickSize(0)
                            //.innerTickSize((settings.chartobj.isBillingVariation) ? -(getTickSize()) : 6)

                            //#region Y axis tick related
                            // limiting the number of ticks to display to 5
                         //  .ticks(5)

                            // a way to force a topmost Y axis value that reflects the max value in the domain
                            //  .tickValues(settings.chartobj.yScales[property].ticks(5).concat(d3.max(settings.chartobj.yScales[property].domain())))
                           //  .tickValues(settings.chartobj.yScales[property].ticks(5).concat(Math.ceil(d3.max(settings.chartobj.yScales[property].domain()))))

                            // a way to force the next tick scale to show - seems to require a chart resize though
                            //.tickValues(function () {
                            //    var scaleTicks = settings.chartobj.yScales[property].ticks(5);
                            //    var diff = scaleTicks[1] - scaleTicks[0];
                            //    scaleTicks.push(scaleTicks[scaleTicks.length - 1] + diff);

                            //    return scaleTicks;

                            //})
                            //#endregion Y axis tick related

                            .tickPadding(getTickSize())
                            .tickFormat(function (d) {
                                // Discussion on formatting availble in D3 :: https://github.com/mbostock/d3/wiki/Formatting#d3_format 

                                var formattedNumber = d;

                                // check to see if commas should be added
                                if (settings.chartobj.yAxisObjs[property].addCommas === true) {
                                    formattedNumber = commasFormatter(formattedNumber);
                                }

                                switch (settings.chartobj.yAxisObjs[property].labelFormat) {

                                    case yAxisFormatOptions.currency:
                                        formattedNumber = "$" + formattedNumber;
                                        break;

                                    case yAxisFormatOptions.temperature:
                                        formattedNumber = formattedNumber + '°';
                                        break;

                                    case yAxisFormatOptions.percentage:
                                        formattedNumber = formattedNumber + '%';
                                        break;

                                    default:
                                        break;

                                }

                                return formattedNumber;
                            });


                        if (settings.chartobj.isBillingVariation) {
                            yAxis.ticks(5);
                        }

                        //#region find xScale to see if there is a rangeband in order to shift the right most Y axis
                        var rightYAxisRangeBandAdjustment = 0;
                        for (var i = 0; i < settings.chartobj.charts.length; i++) {
                            if (settings.chartobj.charts[i].yAxisId === property) {
                                if (settings.chartobj.xScales[settings.chartobj.charts[i].xAxisId].rangeBand()) {
                                    rightYAxisRangeBandAdjustment = Math.round(((settings.chartobj.xScales[settings.chartobj.charts[i].xAxisId].rangeBand() / 2) * paddingBetweenRangeBands) / 2);
                                }
                            }
                        }



                        //#endregion find xScale to see if there is a rangeband in order to shift the right most Y axis




                        // NOTE :: JEF, 11/19/2015 - asked Matt about whether we really care about Y axis tick marks being on the inside
                        //      of the chart for billing variation and he said that he didn't think anyone would care.  So, I have commented
                        //      this adjustment out.  Leave like this for awhile in case someone decides this needs to be back in the chart.

                        // appending Y axis to SVG
                        settings.chartobj.chartSVG.append("g")
                            .attr("class", "yaxis " + property)
                            .attr("transform", function () {
                                if (settings.chartobj.yAxisObjs[property].location === 'right') {
                                    //if (settings.chartobj.isBillingVariation) {
                                    //    return "translate(" + (settings.chartobj.width + getTickSize()) + " ,0)";
                                    //}
                                    //else {


                                    //return "translate(" + settings.chartobj.width + " ,0)";
                                    return "translate(" + (settings.chartobj.width + rightYAxisRangeBandAdjustment) + " ,0)";

                                    //}
                                }
                                //else {
                                //    if (settings.chartobj.isBillingVariation) {
                                //        return "transform", "translate(" + (0 - getTickSize()) + ", 0)";
                                //    }
                                //    else {
                                //        return "";
                                //    }
                                //}
                            })
                            .call(yAxis);



                        //#region adjusting right axis to account for axis stroke width

                        if (settings.chartobj.yAxisObjs[property].location === "right") {
                            var currentAxisRef = settings.chartobj.chartSVG.selectAll("." + property)[0][0];
                            var strokeWidth = parseInt($("." + property).css("stroke-width"));

                            if (strokeWidth !== NaN) {
                                currentAxisRef.setAttribute("transform", "translate(" + (settings.chartobj.width + rightYAxisRangeBandAdjustment + strokeWidth) + ",0)");
                            }
                        }

                        //#endregion adjusting right axis to account for axis stroke width


                        // adjusting y axis element to make room for all axis elements
                        var yAxisRef = settings.chartobj.chartSVG.selectAll(".yaxis")[0][0];
                        var yAxisRect = yAxisRef.getBBox();
                        var chartViewBox = settings.chartobj.chartParent[0][0].getBBox();

                        settings.chartobj.chartParent[0][0].setAttribute("viewBox", -parseInt(yAxisRect.width) + " 0 " + chartViewBox.width + " " + chartViewBox.height);

                        //#endregion creating Y axis and appending it to the chart SVG element



                    }
                }
            }


            function addChartAxisToAxisObj() {

                var dataSeries;
                // loop through chart objects to identify and associate data series for each chart with appropriate axis
                settings.charts.forEach(function (currentChart, i) {

                    // check for need to duplicate the data
                    dataSeries = (settings.options.allowXAxisDuplicateData) ? ko.unwrap(padDataArray(currentChart.series)) : ko.unwrap(currentChart.series);


                    // if this is an update, remove the current x axis obj so these do not accumulate - allows recalc of X scale/axis
                    if (settings.chartobj.isUpdate) {
                        settings.chartobj.xAxisObjs[currentChart.xAxisId].xScaleData.pop();
                        //                settings.chartobj.yAxisObjs[currentChart.yAxisId].yScaleData.pop();
                    }


                    settings.chartobj.xAxisObjs[currentChart.xAxisId].xScaleData.push(dataSeries);
                    settings.chartobj.yAxisObjs[currentChart.yAxisId].yScaleData.push(dataSeries);

                });

            }
            //#endregion axis related




            //#region processing array of individual charts
            function createSpecificChart(currentChart) {

                switch (currentChart.type) {

                    case chartTypes.xy:
                        createBarChart(currentChart);
                        break;

                    case chartTypes.line:
                        createLineChart(currentChart);
                        break;

                    case chartTypes.stackedXY:
                        createStackedChart(currentChart.series);
                        break;

                    default:
                        logChartError("Unknown chart type - " + currentChart.type);
                        break;
                }

                // if axis has negative Y values, add the zero line
                //if (settings.chartobj.yAxisObjs[property].ymin && settings.chartobj.yAxisObjs[property].ymin < 0) {
                if (settings.chartobj.yAxisObjs[currentChart.yAxisId].ymin < 0 || settings.chartobj.yAxisObjs[currentChart.yAxisId].ymax < 0) {
                    addZeroLineMarker(currentChart);
                }


            }

            function processCharts() {

                var currentChart = null;

                settings.charts.forEach(function (currentChart, i) {

                    // creating a unique ID for chart -- used to select chart if its data series updates
                    currentChart.id = "c" + new Date().getTime();



                    createSpecificChart(currentChart);


                    // adding current chart to chartObj to keep track
                    settings.chartobj.charts.push(currentChart);

                    // subscribe to data changes for this chart
                    if (ko.isObservable(currentChart.series)) {

                        currentChart.series.subscribe(function (newValue) {
                            updateChartData.call(settings.chartobj, newValue, currentChart);
                        });

                        // second param to subscribe method sets the value of THIS for callback method
                        //currentChart.series.subscribe(function (newValue) {
                        //    updateChartData(newValue, currentChart);
                        //}, chartObj);
                    }


                });

            }
            //#endregion processing array of individual charts





            //#region main chart functions - create and update
            function createChart($chartContainer, settings, subscription) {

                if (subscription) {
                    subscription.dispose();
                }


                //var chartobj = {};
                settings.chartobj = {};

                settings.chartobj.$chartContainerId = $chartContainer;

                settings.chartobj.isBillingVariation = settings.options.isBillingVariation;

                settings.chartobj.isUpdate = false;                          // flag to indicate updating status of chartObj


                //#region Setting up chart margins, width, height

                settings.chartobj.margin = marginDefinition;

                var containerWidth = settings.chartobj.$chartContainerId.parent().width();
                var containerHeight = settings.chartobj.$chartContainerId.parent().height();




                settings.chartobj.width = containerWidth - settings.chartobj.margin.left - settings.chartobj.margin.right;

                // Width is used to determine the height of the chart.
                settings.chartobj.aspectRatio = (settings.chartobj.width < 400) ? 16 / 9 : 20 / 9;
                settings.chartobj.height = Math.round(settings.chartobj.width / settings.chartobj.aspectRatio) - settings.chartobj.margin.top - settings.chartobj.margin.bottom;

                //#endregion Setting up chart margins, width, height


                // creating the top level SVG element for the chart
                settings.chartobj.chartParent = d3.select($chartContainer[0]);

                //settings.chartobj.chartParent = addChartPatterns(settings.chartobj.chartParent);


                // assigning chartSVG the chart obj that has been created with DEFs section
                settings.chartobj.chartSVG = settings.chartobj.chartParent
                    .attr("width", "100%")
                    .attr("height", "100%")
                    .attr("viewBox", "0 0 " + settings.chartobj.width + " " + settings.chartobj.height)
                    .attr("preserveAspectRatio", "xMinYMin meet")
                    .append("g")
                    .attr("class", "inner-chart");
                //.attr("transform", "translate(" + chartobj.margin.left + "," + chartobj.margin.top + ")");




                //#region tool tip
                // Add tooltip div to the chart container
                settings.chartobj.toolTipDiv = d3.select($chartContainer.parent()[0]).append("div")
                    .attr("class", "fusion-chart-tooltip")
                    .style("opacity", 0);

                //#endregion tool tip




                // #region processing axes
                settings.chartobj.xAxisObjs = {};
                settings.chartobj.yAxisObjs = {};

                // check for an array of x axis objects
                if (settings.options.xAxis.length) {
                    for (var i = 0; i < settings.options.xAxis.length; i++) {
                        settings.chartobj.xAxisObjs[settings.options.xAxis[i].id] = settings.options.xAxis[i];
                        settings.chartobj.xAxisObjs[settings.options.xAxis[i].id].xScaleData = [];
                    }
                }
                else {
                    settings.chartobj.xAxisObjs[settings.options.xAxis.id] = settings.options.xAxis;
                    settings.chartobj.xAxisObjs[settings.options.xAxis.id].xScaleData = [];
                }



                if (settings.options.yAxis.length) {
                    for (var i = 0; i < settings.options.yAxis.length; i++) {
                        settings.chartobj.yAxisObjs[settings.options.yAxis[i].id] = settings.options.yAxis[i];
                        settings.chartobj.yAxisObjs[settings.options.yAxis[i].id].yScaleData = [];
                    }
                }
                else {
                    settings.chartobj.yAxisObjs[settings.options.yAxis.id] = settings.options.yAxis;
                    settings.chartobj.yAxisObjs[settings.options.yAxis.id].yScaleData = [];
                }



                addChartAxisToAxisObj();
                // #endregion processing axes




                //#region setting scales for the chartobj

                // chartobj axis holders
                settings.chartobj.xScales = {};
                settings.chartobj.yScales = {};

                processScales();

                //#endregion setting scales for the chartobj



                //#region processing charts

                //create place to store charts associated with this chartObj
                settings.chartobj.charts = [];


                // loop through the array of charts to build each chart type
                //processCharts(settings.chartobj, settings);
                processCharts();

                //#endregion processing charts



                // Note: axis creation last so that they are on top of other chart elements
                createXAxesElements();
                createYAxesElements();

                return settings.chartobj;
            }


            var updateChartData = function (newValue, currentChart) {

                //$log.trace("in updateChartData, newValue = " + newValue);

                // set update flag on chartObj to indicate chart is updating
                this.isUpdate = true;


                // recall to associate updated data with chart axes
                addChartAxisToAxisObj();


                // recall yScales
                processScales();


                // recall axis creation
                createXAxesElements();
                createYAxesElements();


                // update chart data
                createSpecificChart(currentChart);


                // clear update flag on chartObj once update is done
                this.isUpdate = false;
            }
            //#endregion main chart functions - create and update

        }   // END :: afterDomInsert

    });
});
