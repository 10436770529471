define('fusion/ui/controls/fusion-chartjs',["fusion/fusion.control", "ext/moment", "ext/chart.min"], function (ControlFactory, _, Chart) {
    return ControlFactory.control(function ($, ko, $log, require) {
        var c = this;

        c.settingsDefinition = {
            chart: { isLive: true, defaultValue: {} },
            options: { isLive: false, defaultValue: {}}
        };

        c.validateValues = function (settings) {
        };

        c.beforeBind = function ($markup, settings, bindingContext, $element) {

            var div = $markup.find('.chartjs-container')[0];
            settings.chart(new Chart(div, settings.options));
        };

        c.afterBind = function ($markup, settings, bindingContext, $element) {
        };

        c.afterDomInsert = function ($markup, settings, bindingContext) {
        };
    });
});
