define('fusion/ui/controls/fusion-stepindicator',["fusion/fusion.control"], function (ControlFactory) {
    return ControlFactory.control(function ($, ko) {
        var c = this;


        c.settingsDefinition = {
            steps: { isLive: false, isRequired: true },
            currentStep: { isLive: false, isRequired: false }
        };






        c.validateValues = function (settings) {

            settings("steps").throwIf(function (val) {
                return ko.isObservable(val);
            }, "steps parameter cannot be bound to an observable.");

        }



        c.beforeBind = function ($markup, settings, bindingContext, $element) {

            settings.isCurrent = function (step) {
                return ko.unwrap(step) === ko.unwrap(settings.currentStep);
            }
        }



        c.afterBind = function ($markup, settings, bindingContext, $element) {

        }



        c.afterDomInsert = function ($markup, settings, bindingContext) {


        }




    });
});
