define('fusion/ui/controls/fusion-checkboxgroup-item',["fusion/fusion.control"], function (ControlFactory) {
    return ControlFactory.control(function ($, ko) {
        var c = this;


        c.settingsDefinition = {
            text: { isLive: true, isRequired: false },
            value: { isLive: true, isRequired: false, defaultValue: "" }
        };

        c.validateValues = function (settings) {
        }

        c.beforeBind = function ($markup, settings, bindingContext, $element) {
        }

        c.afterBind = function ($markup, settings, bindingContext, $element) {
        }

        c.afterDomInsert = function ($markup, settings, bindingContext) {
        }

    });
});
