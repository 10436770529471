define("fusion/private/settings-validator", ['require','knockout','fusion/jquery','fusion/log','fusion/utils'],function (require) {

    var ko = require("knockout");
    var $ = require("fusion/jquery");
    var log = require("fusion/log");

    var SettingsValidator = function (settings, elementTagName) {
        var utils = require("fusion/utils");
        var _this = this;

        return $.extend(function (propName) {
            var _errorValue = true;
            var _enforced = false;

            var getValue = function () {
                return ko.utils.unwrapObservable(settings[propName]);
            };

            _this.throwIf = function (predicate, message) {
                if (typeof predicate === "function") {
                    if (predicate(getValue())) {
                        log.error(message || "'" + propName + "' setting is invalid for element " + elementTagName);
                    }
                }
                _enforced = true;
                return _this;
            };

            _this.throwIfNot = function (predicate, message) {
                if (typeof predicate === "function") {
                    if (!predicate(getValue())) {
                        log.error(message || "'" + propName + "' setting is invalid for element " + elementTagName);
                    }
                }
                _enforced = true;
                
                return _this.not();
            };

            _this.ensureObservable = function () {
                if (!ko.isObservable(settings[propName])) {
                    settings[propName] = ko.observable(settings[propName]);
                }
            }

            //_this.defaultTo = function (defaultValue) {
            //    if (utils.isNull(getValue())) {
            //        setValue(defaultValue);
            //    }
            //    return _this;
            //};

            _this.not = function () {
                _errorValue = !_errorValue;
                return _this;
            };

//Do we need this function?
            _this.requireContent = function () {
                if (!settings.__hasContent) {
                    log.error(elementTagName + " element requires content.");
                }
            };

            _this.exists = function () {
                return settings.hasOwnProperty(propName);
            };

            _this.isOneOf = function (values) {
                var retVal = (ko.utils.arrayIndexOf(values, getValue()) >= 0);
                if (_enforced && (retVal === _errorValue)) {
                    var isNot = _errorValue ? "not " : "";
                    log.error("'" + getValue() + "' is not a valid value for the '" + propName + "' setting on element " + elementTagName +
                        "This setting must " + isNot + "be one of the following values: [" + values.toString() + "]");
                }
                return retVal;
            };

            _this.isNullOrEmpty = function () {
                //check if any one of the values is null or empty
                var retVal = utils.isNullOrEmpty(getValue());

                if (_enforced && (retVal === _errorValue)) {
                    var isNot = _errorValue ? "not " : "";
                    log.error("'" + propName + "' setting must " + isNot + "be null or undefined for element " + elementTagName);
                }
                return retVal;
            };

            _this.isBoolean = function () {
                var retVal = typeof (getValue()) === 'boolean';

                if (_enforced && (retVal === _errorValue)) {
                    var isNot = _errorValue ? "not " : "";
                    log.error("'" + propName + "' setting must " + isNot + "be a boolean");
                }
                return retVal;
            };

            _this.isInteger = function () {
                var retVal = utils.isInteger(getValue());

                if (_enforced && (retVal === _errorValue)) {
                    var isNot = _errorValue ? "not " : "";
                    log.error("'" + propName + "' setting must " + isNot + "be an integer");
                }

                return retVal;
            };

            _this.isObservable = function () {
                var retVal = ko.isObservable(getValue());

                if (_enforced && (retVal === _errorValue)) {
                    var isNot = _errorValue ? "not " : "";
                    log.error("'" + propName + "' setting must " + isNot + "be an observable");
                }

                return retVal;
            };

            _this.isDate = function () {
                var retVal = utils.isDate(getValue());

                if (_enforced && (retVal === _errorValue)) {
                    var isNot = _errorValue ? "not " : "";
                    log.error("'" + propName + "' setting must " + isNot + "be a Date");
                }

                return retVal;
            };

            _this.isArray = function () {
                var retVal = utils.isArray(getValue());

                if (_enforced && (retVal === _errorValue)) {
                    var isNot = _errorValue ? "not " : "";
                    log.error("'" + propName + "' setting must " + isNot + "be an Array");
                }

                return retVal;
            };

            return _this;
        }, settings);
    };


    return SettingsValidator;

});
