define('fusion/ui/controls/fusion-date',[
    "fusion/fusion.control",
    "ext/jquery-inputmask"
], function (ControlFactory) {
    return ControlFactory.control(function ($, $log, require) {
        var c = this;

        //require("ext/jquery-inputmask");

        c.settingsDefinition = {
            value: { isLive: true, defaultValue: '' },
            labelText: { isLive: true, defaultValue: null },
            placeholder: { isLive: true, defaultValue: null },
            helperText: { isLive: true, defaultValue: null },
            isReadOnly: { isLive: true, defaultValue: false }
        };


        c.validateValues = function (settings) {

            // checking for direct content
            if (settings.__hasDirectContent) {
                var msg = "fusion-date does not support direct content";
                $log.warning(msg);
                //throw new Error(msg);
            }
        }

        c.beforeBind = function ($markup, settings, bindingContext, $element) {
        }

        c.afterBind = function ($markup, settings, bindingContext, $element) {
        }

        c.afterDomInsert = function ($markup, settings, bindingContext) {
            $markup.find("input").inputmask('mm/dd/yyyy',
            {
                placeholder: '-'
            });

        }

    });
});
